import * as React from 'react';

import { Button, COLORS, InfoModal, Text } from '@clutter/clean';

import { useState } from 'react';

import { StreetAddress } from '@portal/components/addresses/form/street_address';
import { AptSuite } from '@portal/components/addresses/form/apt_suite';
import { DEFAULT_ADDRESS, DEFAULT_DETAILS } from '@portal/components/addresses/utils';
import { AddressDetailField } from '@shared/types/address_detail';
import { IAddressDetail } from '@shared/types/address_detail';
import { IAddressWithDetails } from '@shared/types/address';

import styled from '@emotion/styled';
import { BuildingType } from './form/building_type';

import * as Utils from './utils';

const ErrorMessage = styled(Text.Callout)`
  color: ${COLORS.toucan};
  margin-bottom: 8px;
`;

export const Modal: React.FC<{
  isOpen: boolean;
  error?: string;
  loading?: boolean;
  withBuildingType?: boolean;
  onClose(): void;
  onAddAddress(address: IAddressWithDetails): void;
}> = ({ loading, withBuildingType, error, isOpen, onClose, onAddAddress }) => {
  const [address, setAddress] = useState<IAddressWithDetails>({ ...DEFAULT_ADDRESS, details: { ...DEFAULT_DETAILS } });

  const validAddress =
    !!address.street &&
    !!address.city &&
    !!address.state &&
    !!address.zip &&
    (!withBuildingType || (withBuildingType && !!address?.details?.buildingType));

  return (
    <InfoModal isOpen={isOpen} handleModalClose={onClose}>
      <StreetAddress address={address} onSelect={(value) => setAddress({ ...address, ...value })} />
      <AptSuite
        address={address}
        disabled={!address}
        onChange={(aptsuite) => setAddress({ ...address, aptsuite: aptsuite || undefined })}
      />
      {withBuildingType && (
        <BuildingType
          disabled={!address}
          details={address.details || Utils.DEFAULT_DETAILS}
          onChange={(buildingType) =>
            setAddress({
              ...address,
              details: {
                ...(address.details as IAddressDetail),
                [AddressDetailField.BuildingType]: buildingType,
              },
            })
          }
        />
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Button
        kind="primary"
        size="medium"
        disabled={!validAddress}
        loading={loading}
        onClick={() => onAddAddress(address)}
      >
        Add Address
      </Button>
    </InfoModal>
  );
};
