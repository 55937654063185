import { take } from 'lodash';
import * as React from 'react';
import { useState } from 'react';

import { Button, Text } from '@shared/components/bootstrap';
import { IEstimatedItem } from '@shared/types/estimated_item';

const DEFAULT_VIEWABLE_SELECTION_COUNT = 4;

export const EstimatedItemSelections: React.FC<{ selections: IEstimatedItem[] }> = ({ selections }) => {
  const [more, setMore] = useState<boolean>(false);
  const filtered = more ? selections : take(selections, DEFAULT_VIEWABLE_SELECTION_COUNT);
  return (
    <>
      {filtered.map(({ quantity, categoryDisplayName, customCategoryName, length, width, height, notes }, index) => (
        <React.Fragment key={index}>
          <Text tag="p" style="dark">
            <span className="text-capitalize">
              {quantity} × {categoryDisplayName || customCategoryName}
            </span>
            {!!length && !!width && !!height && (
              <>
                {' '}
                ({length}" x {width}" x {height}")
              </>
            )}
            {!!notes && notes.length && <>: {notes}</>}
          </Text>
        </React.Fragment>
      ))}
      {selections.length > DEFAULT_VIEWABLE_SELECTION_COUNT && (
        <Text tag="p" alignment="center">
          <Button block kind="light" onClick={() => setMore(!more)}>
            {more ? 'See Fewer' : 'See All'}
          </Button>
        </Text>
      )}
    </>
  );
};
