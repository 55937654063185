enum OrderServiceTypeEnum {
  Auction = 'AUCTION',
  Capsule = 'CAPSULE',
  CurbsidePickup = 'CURBSIDE_PICKUP',
  Disposal = 'DISPOSAL',
  Doorstep = 'DOORSTEP',
  DropOff = 'DROP_OFF',
  Facility = 'FACILITY',
  FullService = 'FULL_SERVICE',
  LongDistance = 'LONG_DISTANCE',
  Shipment = 'SHIPMENT',
  ThirdPartyReturn = 'THIRD_PARTY_RETURN',
}

export const DISPLAYED_SERVICE_TYPE_MAP: Record<OrderServiceTypeEnum, string> = {
  [OrderServiceTypeEnum.Capsule]: 'Capsule',
  [OrderServiceTypeEnum.CurbsidePickup]: 'Pickup Only',
  [OrderServiceTypeEnum.Disposal]: 'Disposal',
  [OrderServiceTypeEnum.Doorstep]: 'Doorstep',
  [OrderServiceTypeEnum.DropOff]: 'Drop-off',
  [OrderServiceTypeEnum.FullService]: 'Full Service',
  [OrderServiceTypeEnum.Shipment]: 'Shipment',
  [OrderServiceTypeEnum.ThirdPartyReturn]: 'Third-party Return',
  [OrderServiceTypeEnum.Auction]: 'Auction',
  [OrderServiceTypeEnum.Facility]: 'Facility',
  [OrderServiceTypeEnum.LongDistance]: 'Long-Distance',
};
