import * as React from 'react';

import { AddressDetailField } from '@shared/types/address_detail';
import { IAddressDetail } from '@shared/types/address_detail';

import { InputFormGroup } from '../input_form_group';

export const BuildingFloor: React.FC<{
  details: IAddressDetail;
  error?: string;
  onChange(value: number | null): void;
}> = ({ details, onChange, error }) => (
  <InputFormGroup
    label="Floor number"
    type="number"
    step={1}
    min={0}
    value={details[AddressDetailField.Floor] === null ? '' : Number(details[AddressDetailField.Floor])}
    error={!details[AddressDetailField.Floor] ? error : undefined}
    onChange={(event) => {
      if (event.target.value === '') {
        onChange(null);
      } else {
        onChange(Number(event.target.value));
      }
    }}
  />
);
