import * as React from 'react';
import { useContext } from 'react';

import { Button, Text } from '@shared/components/bootstrap';
import { IEstimatedItem } from '@shared/types/estimated_item';

import { EstimatedItemsForm } from '@shared/components/estimated_items/form';
import { InventoryContext } from '@shared/components/inventory_capture/inventory_context';

export const AdditionalItems: React.FC = () => {
  const { data, onSelect } = useContext(InventoryContext);

  const allItemsValid = () => data.extraItems && data.extraItems.every((item) => !!item.quantity && item.quantity > 0);

  return (
    <>
      <Text tag="p" style="info">
        <strong>What are those items?</strong>
      </Text>

      <EstimatedItemsForm
        estimatedItems={data.extraItems}
        onSave={(items: IEstimatedItem[]) => onSelect({ ...data, extraItems: items })}
      />

      {!data.extraItemsComplete && (
        <Button
          block
          outline
          kind="primary"
          onClick={() => onSelect({ ...data, extraItemsComplete: true })}
          disabled={!allItemsValid()}
        >
          Next
        </Button>
      )}
    </>
  );
};
