import { isNil } from 'lodash';

import { AddressDetailBuildingType } from '@shared/types/address_detail';
import { AddressDetailField } from '@shared/types/address_detail';
import { IAddress } from '@shared/types/address';
import { IAddressDetail } from '@shared/types/address_detail';
import { IAddressWithDetails } from '@shared/types/address';

export const HOME_BUILDING_TYPE_OPTIONS = [AddressDetailBuildingType.House];

export const UNIT_BUILDING_TYPE_OPTIONS = [AddressDetailBuildingType.Apartment, AddressDetailBuildingType.Dorm];

export const BUSINESS_BUILDING_TYPE_OPTIONS = [
  AddressDetailBuildingType.Commercial,
  AddressDetailBuildingType.DonationCenter,
  AddressDetailBuildingType.Warehouse,
];

const BUILDINGS_WITH_SUBTYPE = [AddressDetailBuildingType.Apartment, AddressDetailBuildingType.Commercial];

const FIELDS_REQUIRED_ALWAYS = [AddressDetailField.BuildingRestrictionsAnswer, AddressDetailField.BuildingType];

const FIELDS_REQUIRED_FOR_WAREHOUSES = [AddressDetailField.Environment];

const FIELDS_REQUIRED_FOR_UNITS = [AddressDetailField.Floor];

type AccessFields =
  | AddressDetailField.AccessUnknown
  | AddressDetailField.Stairs
  | AddressDetailField.Elevator
  | AddressDetailField.ServiceElevator;
export const FIELDS_FOR_ACCESS: AccessFields[] = [
  AddressDetailField.AccessUnknown,
  AddressDetailField.Stairs,
  AddressDetailField.Elevator,
  AddressDetailField.ServiceElevator,
];

const FIELDS_FOR_METADATA = [
  AddressDetailField.ParkingOnSpecificStreet,
  AddressDetailField.Code,
  AddressDetailField.CodeValue,
  AddressDetailField.BuildingSubtype,
];

type ParkingFields =
  | AddressDetailField.ParkingUnknown
  | AddressDetailField.ParkingStreet
  | AddressDetailField.ParkingLot
  | AddressDetailField.ParkingInFront
  | AddressDetailField.ParkingAlley
  | AddressDetailField.ParkingDriveway;
export const FIELDS_FOR_PARKING: ParkingFields[] = [
  AddressDetailField.ParkingUnknown,
  AddressDetailField.ParkingStreet,
  AddressDetailField.ParkingLot,
  AddressDetailField.ParkingInFront,
  AddressDetailField.ParkingAlley,
  AddressDetailField.ParkingDriveway,
];

const FIELDS_FOR_PAPERWORK = [AddressDetailField.Paperwork, AddressDetailField.PaperworkUnknown];

export const DEFAULT_ADDRESS: IAddress = {
  street: '',
  city: '',
  state: '',
  zip: '',
};

export const DEFAULT_DETAILS: IAddressDetail = {
  [AddressDetailField.Floor]: null,
  [AddressDetailField.Stories]: null,
  [AddressDetailField.FrontDoorSteps]: null,
  [AddressDetailField.SquareFeet]: null,

  [AddressDetailField.Stairs]: false,
  [AddressDetailField.Elevator]: false,
  [AddressDetailField.Paperwork]: false,
  [AddressDetailField.Gated]: false,
  [AddressDetailField.Steep]: false,
  [AddressDetailField.ServiceElevator]: false,
  [AddressDetailField.ServiceEntrance]: false,
  [AddressDetailField.LoadingDock]: false,

  [AddressDetailField.Code]: false,
  [AddressDetailField.CodeValue]: null,

  [AddressDetailField.ParkingStreet]: false,
  [AddressDetailField.ParkingDriveway]: false,
  [AddressDetailField.ParkingLot]: false,
  [AddressDetailField.ParkingAlley]: false,
  [AddressDetailField.ParkingPermit]: false,
  [AddressDetailField.ParkingInFront]: false,
  [AddressDetailField.ParkingOnSpecificStreet]: null,
  [AddressDetailField.ParkingInstructions]: null,

  [AddressDetailField.AccessUnknown]: false,
  [AddressDetailField.PaperworkUnknown]: false,
  [AddressDetailField.ParkingUnknown]: false,

  [AddressDetailField.MaxTruckHeight]: null,
  [AddressDetailField.MaxTruckHeightUnknown]: false,

  [AddressDetailField.Environment]: null,

  [AddressDetailField.AdditionalNotes]: null,

  [AddressDetailField.BuildingOpeningHour]: null,
  [AddressDetailField.BuildingClosingHour]: null,
  [AddressDetailField.BuildingRestrictionsAnswer]: null,
};

export const isUnit = (buildingType?: string | null) =>
  UNIT_BUILDING_TYPE_OPTIONS.some((type) => type === buildingType);
export const isHome = (buildingType?: string | null) =>
  HOME_BUILDING_TYPE_OPTIONS.some((type) => type === buildingType);

export const isBusiness = (buildingType?: string | null) =>
  BUSINESS_BUILDING_TYPE_OPTIONS.some((type) => type === buildingType);
const isWarehouse = (buildingType?: string | null) => buildingType === AddressDetailBuildingType.Warehouse;
const hasSubtype = (buildingType?: string | null) => BUILDINGS_WITH_SUBTYPE.some((type) => type === buildingType);

export const requireAptSuite = isUnit;

export const requireAccess = isUnit;

const hasDwelling = ({ details, ...address }: IAddressWithDetails) => {
  const buildingType = details && details[AddressDetailField.BuildingType];

  return (
    !!address &&
    !!address.street &&
    details &&
    buildingType &&
    (!requireAptSuite(buildingType) || !!address.aptsuite) &&
    FIELDS_REQUIRED_ALWAYS.every((field) => !!details[field]) &&
    (!isUnit(buildingType) || FIELDS_REQUIRED_FOR_UNITS.every((field) => !isNil(details[field]))) &&
    (!requireAccess(buildingType) || FIELDS_FOR_ACCESS.some((field) => !!details[field])) &&
    (!isBusiness(buildingType) || !!address.businessName)
  );
};

const hasMetadata = ({ details }: IAddressWithDetails) =>
  details &&
  FIELDS_FOR_METADATA.every((field) => !isRequired({ field, details }) || !!details[field]) &&
  FIELDS_FOR_PARKING.some((field) => !!details[field]) &&
  FIELDS_FOR_PAPERWORK.some((field) => details[field] !== undefined);

const shippable = ({ details, ...address }: IAddressWithDetails) => {
  const buildingType = details && details[AddressDetailField.BuildingType];

  return (
    !!address &&
    !!address.street &&
    details &&
    buildingType &&
    (!requireAptSuite(buildingType) || !!address.aptsuite) &&
    (!isBusiness(buildingType) || !!address.businessName)
  );
};

export const isRequired = ({ field, details }: { field: AddressDetailField; details: IAddressDetail }): boolean =>
  FIELDS_REQUIRED_ALWAYS.some((required) => required === field) ||
  (isUnit(details[AddressDetailField.BuildingType]) &&
    FIELDS_REQUIRED_FOR_UNITS.some((required) => required === field)) ||
  (hasSubtype(details[AddressDetailField.BuildingType]) && field === AddressDetailField.BuildingSubtype) ||
  (isWarehouse(details[AddressDetailField.BuildingType]) &&
    FIELDS_REQUIRED_FOR_WAREHOUSES.some((required) => required === field)) ||
  (field === AddressDetailField.ParkingOnSpecificStreet && details[AddressDetailField.ParkingStreet]) ||
  (field === AddressDetailField.CodeValue && details[AddressDetailField.Code]);

const TEXT_INPUT_ERROR = 'This field is required';
const SELECT_INPUT_ERROR = 'Please select an option';

const resolveValidationCopy = (valid: boolean, inputType: 'text' | 'select') => {
  if (!valid) {
    return inputType === 'text' ? TEXT_INPUT_ERROR : SELECT_INPUT_ERROR;
  } else {
    return undefined;
  }
};

export const validate = ({ details, ...address }: IAddressWithDetails, shipment?: boolean) => {
  const buildingType = details && details[AddressDetailField.BuildingType];
  const parkingLocationSelected =
    !!details?.[AddressDetailField.ParkingUnknown] ||
    !!details?.[AddressDetailField.ParkingStreet] ||
    !!details?.[AddressDetailField.ParkingLot] ||
    !!details?.[AddressDetailField.ParkingInFront] ||
    !!details?.[AddressDetailField.ParkingAlley] ||
    !!details?.[AddressDetailField.ParkingDriveway];
  const access =
    !!details?.[AddressDetailField.AccessUnknown] ||
    !!details?.[AddressDetailField.Stairs] ||
    !!details?.[AddressDetailField.Elevator] ||
    !!details?.[AddressDetailField.ServiceElevator];

  const defaultValidation = {
    streetAddress: resolveValidationCopy(!!address.street, 'text'),
    [AddressDetailField.BuildingType]: resolveValidationCopy(!!details?.[AddressDetailField.BuildingType], 'select'),
    [AddressDetailField.BuildingRestrictionsAnswer]: resolveValidationCopy(
      !!details?.[AddressDetailField.BuildingRestrictionsAnswer],
      'select',
    ),
    parkingLocation: resolveValidationCopy(parkingLocationSelected, 'select'),
  };

  const unitValidation = {
    aptsuite: resolveValidationCopy(!!address?.aptsuite, 'text'),
    access: resolveValidationCopy(access, 'select'),
    [AddressDetailField.BuildingSubtype]: resolveValidationCopy(
      !!details?.[AddressDetailField.BuildingSubtype],
      'select',
    ),
    [AddressDetailField.Floor]: resolveValidationCopy(!!details?.[AddressDetailField.Floor], 'text'),
    ...defaultValidation,
  };

  const warehouseValidation = {
    businessName: resolveValidationCopy(!!address?.businessName, 'text'),
    ...defaultValidation,
  };

  const commercialValidation = {
    [AddressDetailField.BuildingSubtype]: resolveValidationCopy(
      !!details?.[AddressDetailField.BuildingSubtype],
      'select',
    ),
    businessName: resolveValidationCopy(!!address?.businessName, 'text'),
    ...defaultValidation,
  };

  const donationCenterValidation = {
    businessName: resolveValidationCopy(!!address?.businessName, 'text'),
    ...defaultValidation,
  };

  const shipmentValidation = {
    streetAddress: resolveValidationCopy(!!address.street, 'text'),
    [AddressDetailField.BuildingType]: resolveValidationCopy(!!details?.[AddressDetailField.BuildingType], 'select'),
    aptsuite: resolveValidationCopy(Boolean(buildingType && (!isUnit(buildingType) || !!address?.aptsuite)), 'text'),
    businessName: resolveValidationCopy(
      Boolean(buildingType && (!isBusiness(buildingType) || !!address?.businessName)),
      'text',
    ),
  };

  if (shipment) {
    return shipmentValidation;
  } else if (!buildingType || buildingType === AddressDetailBuildingType.Other || isHome(buildingType)) {
    return defaultValidation;
  } else if (isUnit(buildingType)) {
    return unitValidation;
  } else if (isWarehouse(buildingType)) {
    return warehouseValidation;
  } else if (buildingType === AddressDetailBuildingType.Commercial) {
    return commercialValidation;
  } else if (buildingType === AddressDetailBuildingType.DonationCenter) {
    return donationCenterValidation;
  } else {
    return {};
  }
};

export const redirectToAccount = () => window.location.replace('/account');

export const disabled = (address: IAddressWithDetails, shipment?: boolean) => {
  if (shipment) {
    return !shippable(address);
  } else {
    return !hasDwelling(address) || !hasMetadata(address);
  }
};

export const valid = (address: IAddressWithDetails) => !disabled(address);
