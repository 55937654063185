import React from 'react';

import { ClaimChanger, ClaimConsumer } from '@portal/contexts';

import {
  DetailedClaimFragment,
  Status,
  useClaimIssueSelectionDestroyMutation,
  useDestroyClaimItemSelectionMutation,
  useDestroyClaimPropertySelectionMutation,
} from '@portal/schema';
import { ClaimEntryKind } from '@portal/types/claim_entry_kind';
import { IClaimEntry } from '@portal/types/claim_entry';

import { Alert, Button, Modal } from '@shared/components/bootstrap';
import { CLAIM_ISSUE_SELECTION_KIND_TO_NAME, CLAIM_ISSUE_SELECTION_NAME_PLACEHOLDER } from '../constants';

type DestroyProps = {
  claim: DetailedClaimFragment;
  entry: IClaimEntry;
  close(): void;
  onChange(changer: ClaimChanger): void;
  children(props: { destroying: boolean; error?: string; destroy(): void }): React.ReactElement;
};

const Destroyer = ({ claim: { uuid }, entry, onChange, close, children }: DestroyProps) => {
  const [destroyIssue, { loading: destroyingIssue, data: dataIssue }] = useClaimIssueSelectionDestroyMutation();
  const [destroyItem, { loading: destroyingItem, data: dataItem }] = useDestroyClaimItemSelectionMutation();
  const [destroyProperty, { loading: destroyingProperty, data: dataProperty }] =
    useDestroyClaimPropertySelectionMutation();
  switch (entry.kind) {
    case ClaimEntryKind.Issue:
      return children({
        destroying: destroyingIssue,
        error: dataIssue?.claimIssueSelectionDestroy?.error ?? undefined,
        destroy: async () => {
          const result = await destroyIssue({ variables: { uuid } });
          if (result.data?.claimIssueSelectionDestroy?.status !== Status.Ok) {
            return;
          }
          onChange((claim) => ({
            ...claim,
            issueSelection: undefined,
          }));
          close();
        },
      });
    case ClaimEntryKind.Item:
      return children({
        destroying: destroyingItem,
        error: dataItem?.destroyClaimItemSelection?.error ?? undefined,
        destroy: async () => {
          const result = await destroyItem({ variables: { uuid, selectionID: entry.selection.id } });
          if (result.data?.destroyClaimItemSelection?.status !== Status.Ok) {
            return;
          }
          onChange((claim) => ({
            ...claim,
            itemSelections: claim.itemSelections.filter(({ id }) => id !== entry.selection.id),
          }));
          close();
        },
      });
    case ClaimEntryKind.Property:
      return children({
        destroying: destroyingProperty,
        error: dataProperty?.destroyClaimPropertySelection?.error ?? undefined,
        destroy: async () => {
          const result = await destroyProperty({ variables: { uuid } });
          if (result.data?.destroyClaimPropertySelection?.status !== Status.Ok) {
            return;
          }
          onChange((claim) => ({
            ...claim,
            propertySelection: undefined,
          }));
          close();
        },
      });
  }
};

type DeleteProps = {
  entry: IClaimEntry;
  onClose(): void;
};

export const Delete = ({ entry, onClose }: DeleteProps) => {
  const entryName =
    entry.kind === ClaimEntryKind.Issue
      ? entry.selection.kind
        ? CLAIM_ISSUE_SELECTION_KIND_TO_NAME[entry.selection.kind]
        : CLAIM_ISSUE_SELECTION_NAME_PLACEHOLDER
      : `‘${entry.selection.name || 'UNNAMED'}’`;
  return (
    <ClaimConsumer>
      {({ claim, onChange }) => (
        <Modal centered onClose={onClose}>
          <Destroyer claim={claim} entry={entry} close={onClose} onChange={onChange}>
            {({ destroying, destroy, error }) => (
              <Modal.Content>
                <Modal.Header>
                  <Modal.Title tag="h6">Remove {entryName} Claim</Modal.Title>
                  <Modal.Close close={onClose} disabled={destroying} />
                </Modal.Header>
                <Modal.Body>
                  {error && <Alert style="danger">{error}</Alert>}
                  Are you sure you want to remove {entryName} claim?
                </Modal.Body>
                <Modal.Footer>
                  <Button kind="light" disabled={destroying} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button kind="danger" loading={destroying} onClick={destroy}>
                    Remove
                  </Button>
                </Modal.Footer>
              </Modal.Content>
            )}
          </Destroyer>
        </Modal>
      )}
    </ClaimConsumer>
  );
};
