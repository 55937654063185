import React from 'react';
import { Link } from 'react-router-dom';
import { Text } from '@clutter/clean';

import { OrderStatus } from '@shared/types/order';
import { IOrder } from '@shared/types/order';

import { orderURL } from '@portal/config/routes';
import { Spacer } from '@shared/components/helpers';

export const Scheduled: React.FC<{ order: IOrder }> = ({ order }) => {
  const { number, shipped, status } = order;

  return (
    <>
      <Spacer height="1rem" />
      <Text.Title size="large">Shipment #{number}</Text.Title>

      {status !== OrderStatus.Canceled && (
        <>
          <Text.Body>
            {shipped
              ? 'Your items have shipped, see below for tracking information.'
              : "Your shipment is being processed. You'll receive tracking information once your items ship."}
          </Text.Body>

          {order.permissions.active && (
            <>
              {order.permissions.reschedulable ? (
                <Link className="btn btn-primary" to={orderURL(order.id, 'reschedule')}>
                  Reschedule
                </Link>
              ) : (
                <>
                  <Spacer height="2rem" />
                  <Text.Body>
                    {shipped
                      ? 'This shipment cannot be rescheduled.'
                      : 'This shipment cannot be rescheduled. Please cancel if you can no longer receive the shipment.'}
                  </Text.Body>
                </>
              )}
            </>
          )}
        </>
      )}
      <hr />
    </>
  );
};
