import {
  ClaimIssueSelectionFragment,
  ClaimItemSelectionFragment,
  ClaimItemSelectionKind,
  ClaimPropertySelectionFragment,
  DetailedClaimFragment,
} from '@portal/schema';
import { ClaimEntryKind } from '@portal/types/claim_entry_kind';
import { IClaimEntry } from '@portal/types/claim_entry';

export const entries = (claim: DetailedClaimFragment): IClaimEntry[] => {
  const results: IClaimEntry[] = [];

  const {
    hasDamagedItem,
    hasDamagedProperty,
    hasLostItem,
    hasIssue,
    itemSelections,
    propertySelection,
    issueSelection,
  } = claim;

  if (hasDamagedItem) {
    const selections = itemSelections.filter(({ kind }) => kind === ClaimItemSelectionKind.Damaged);
    results.push(...selections.map((selection): IClaimEntry => ({ selection, kind: ClaimEntryKind.Item })));
  }

  if (hasLostItem) {
    const selections = itemSelections.filter(({ kind }) => kind === ClaimItemSelectionKind.Lost);
    results.push(...selections.map((selection): IClaimEntry => ({ selection, kind: ClaimEntryKind.Item })));
  }

  if (hasDamagedProperty && propertySelection) {
    results.push({ selection: propertySelection, kind: ClaimEntryKind.Property });
  }

  if (hasIssue && issueSelection) {
    results.push({ selection: issueSelection, kind: ClaimEntryKind.Issue });
  }

  return results;
};

export const isValidClaimItemSelection = ({ kind, name, declaration, photos, tracked }: ClaimItemSelectionFragment) =>
  !!name && !!declaration && (kind === ClaimItemSelectionKind.Lost || tracked || !!photos.length);

export const isValidClaimPropertySelection = ({
  name,
  declaration,
  ownership,
  photos,
}: ClaimPropertySelectionFragment) => !!name && !!declaration && !!ownership && !!photos.length;

export const isValidClaimIssueSelection = ({
  kind,
  declaration,
  description,
  compensationRequested,
}: ClaimIssueSelectionFragment) =>
  !!kind && !!description && ((!!declaration && !!compensationRequested) || compensationRequested === false);

export const isValidClaimEntry = (entry: IClaimEntry): boolean => {
  switch (entry.kind) {
    case ClaimEntryKind.Issue:
      return isValidClaimIssueSelection(entry.selection);
    case ClaimEntryKind.Item:
      return isValidClaimItemSelection(entry.selection);
    case ClaimEntryKind.Property:
      return isValidClaimPropertySelection(entry.selection);
  }
};

export const isValidClaim = (claim: DetailedClaimFragment): boolean =>
  entries(claim).length > 0 && entries(claim).every(isValidClaimEntry);
