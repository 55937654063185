import { AddressWithDetailsFragment, OrderDetailsDocument, useUpdateOrderAddressMutation } from '@portal/schema';
import { Box, Button, mq } from '@clutter/clean';
import React, { useState } from 'react';
import { Modal as AddressModal } from '@portal/components/addresses/modal';

import { COLORS, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import { IAddressWithDetails } from '@shared/types/address';
import { serialize } from '../form/mutation';

const Card = styled.div`
  max-width: 378px;
  border: 2px solid ${COLORS.grayBorder};
  background: ${COLORS.cloud};
  border-radius: 4px;
  display: block;
  position: relative;
  flex: 1;

  ${mq({
    padding: ['12px', null, '16px 12px'],
  })}
`;

export const StorageAddress: React.FC<{
  pickupAddress: AddressWithDetailsFragment;
  orderID: string;
  onlyAllowAddressDetailChanges: boolean;
}> = ({ pickupAddress, orderID, onlyAllowAddressDetailChanges }) => {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState<string>();

  const [saveAddress, { loading }] = useUpdateOrderAddressMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: OrderDetailsDocument, variables: { orderID } }],
  });

  const onAddAddress = async (address: IAddressWithDetails) => {
    const response = await saveAddress({
      variables: {
        orderID: orderID,
        addressInput: serialize({ ...address }),
      },
    });
    if (response.data?.result?.error) {
      setError(response.data.result.error);
    } else {
      setShowModal(false);
    }
  };

  return (
    <Box margin="20px 0 40px">
      <Box.Flex gap="16px" flexDirection={['column', null, 'row']}>
        <Card>
          <Text.Callout>Address</Text.Callout>
          <Text.Title size="extraSmall">{pickupAddress.street}</Text.Title>
          <Text.Caption color={COLORS.hippo}>
            {pickupAddress.city}, {pickupAddress.state} {pickupAddress.zip}
          </Text.Caption>
        </Card>
      </Box.Flex>
      <AddressModal
        isOpen={showModal}
        error={error}
        loading={loading}
        onClose={() => setShowModal(false)}
        onAddAddress={onAddAddress}
      />
      <Box margin="16px 0 0">
        <Button
          kind="secondary"
          type="button"
          onClick={() => setShowModal(true)}
          disabled={onlyAllowAddressDetailChanges}
        >
          Edit Address
        </Button>
      </Box>
    </Box>
  );
};
