import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Spinner } from '@portal/components/helpers';
import { orderURL } from '@portal/config/routes';
import {
  Order__ContactKind,
  Order__PickupVehicleKind,
  Status,
  useOrderFormContactQuery,
  useUpdateOrderContactMutation,
} from '@portal/schema';
import { FlashKind } from '@shared/types/flash';

import { OrderContactForm } from './contact/form';
import { Container } from './container';

const UNABLE_TO_EDIT_MESSAGE = 'The contact can no longer be updated for this order.';
const SUCCESS_MESSAGE = 'Order has been successfully updated.';

export const Contact: React.FC<RouteComponentProps<{ orderID: string }>> = ({
  match: {
    params: { orderID },
  },
  history,
}) => {
  const [error, setError] = useState<string | undefined>();

  const { data, loading } = useOrderFormContactQuery({ variables: { orderID } });
  const [updateContact, { loading: updating }] = useUpdateOrderContactMutation();

  const onSubmit = async (
    name: string,
    phone: string,
    kind?: Order__ContactKind,
    pickupVehicleKind?: Order__PickupVehicleKind,
  ) => {
    setError(undefined);
    const response = await updateContact({
      variables: {
        input: {
          orderID,
          contact: {
            name,
            phone,
            kind,
          },
          pickupVehicleKind,
        },
      },
    });
    if (response.data?.updateOrderContact) {
      if (response.data.updateOrderContact.status === Status.Unprocessable) {
        setError(response.data.updateOrderContact.error || 'There was an error.');
      } else {
        history.push(orderURL(orderID), {
          flash: {
            kind: FlashKind.Success,
            message: SUCCESS_MESSAGE,
          },
        });
      }
    }
  };

  if (loading || !data) {
    return <Spinner />;
  }

  const { account, order } = data;

  if (order.permissions.locked) {
    history.push(orderURL(orderID), {
      flash: {
        kind: FlashKind.Danger,
        message: UNABLE_TO_EDIT_MESSAGE,
      },
    });
  }

  return (
    <Container breadcrumb="Contact" orderID={order.id} scheduled={order.scheduled} error={error}>
      <OrderContactForm
        serviceType={order.serviceType}
        customer={account.customer}
        savedVehicleKind={order.pickupVehicleKind}
        contact={order.contact}
        loading={updating}
        onSubmit={onSubmit}
      />
    </Container>
  );
};
