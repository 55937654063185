import { Blob, DirectUpload } from '@rails/activestorage';
import { useEffect, useRef, useState } from 'react';

const DEFAULT_UPLOAD_URL = '/rails/active_storage/direct_uploads';

export type ActiveSupportDirectUploadUploader = (params: {
  file: File;
  onUpload(blob: Blob): void;
  onError(error: Error | string): void;
}) => {
  uploading: boolean;
  loaded: number;
  total: number;
  retry(): void;
};

export const useActiveStorageDirectUpload: ActiveSupportDirectUploadUploader = ({ file, onError, onUpload }) => {
  const [uploading, setUploading] = useState<boolean>(true);
  const [retries, setRetries] = useState<number>(0);
  const [loaded, setLoaded] = useState<number>(0);
  const [total, setTotal] = useState<number>(file.size);

  const onErrorRef = useRef(onError);
  const onUploadRef = useRef(onUpload);

  useEffect(() => {
    onErrorRef.current = onError;
  }, [onError]);

  useEffect(() => {
    onUploadRef.current = onUpload;
  }, [onUpload]);

  const retry = () => {
    setLoaded(0);
    setTotal(file.size);
    setRetries((count) => count + 1);
  };

  useEffect(() => {
    let active = true;

    setUploading(true);

    const upload = new DirectUpload(file, DEFAULT_UPLOAD_URL, {
      directUploadWillStoreFileWithXHR: (request) => {
        request.upload.addEventListener('progress', (event) => {
          if (!active) return;

          setLoaded(event.loaded);
          setTotal(event.total);
        });
      },
    });

    upload.create((error, blob) => {
      if (!active) return;

      setUploading(false);

      if (error) onErrorRef.current(error);
      else onUploadRef.current(blob);
    });

    return () => {
      active = false;

      setUploading(false);
    };
  }, [file, retries]);

  return {
    uploading,
    loaded,
    total,
    retry,
  };
};
