import { IEstimationCategory } from '@shared/types/estimation_category';
import { IEstimationSelection } from '@shared/types/estimation_selection';
import { IInventoryConfig } from '@shared/types/inventory_config';
import { IOrderInventory } from '@shared/types/order_inventory';
import { IPickupInventoryInput } from '@shared/types/pickup_inventory_input';
import { IRoomCategory } from '@shared/types/room_category';
import { IRoomSelection } from '@shared/types/room_selection';

export const buildOrderInventory = (inventory: IPickupInventoryInput, config: IInventoryConfig): IOrderInventory => {
  const rooms = new Map<IRoomCategory, Partial<IRoomSelection>>();
  const packableEstimations = new Map<IEstimationCategory, Partial<IEstimationSelection>>();
  const unpackableEstimations = new Map<IEstimationCategory, Partial<IEstimationSelection>>();
  const isFullMoveOut = inventory.isFullMoveOut;
  // Full move outs don't get asked if they need packing help - we default it to true. We want to reset it here so
  // switching to 'Storing extra things' will force them to answer the needs packing help question.
  const needsPackingHelp = isFullMoveOut ? undefined : inventory.needsPackingHelp;
  const requestedMovers = inventory.requestedMovers ? inventory.requestedMovers : 0;
  const extraItems = inventory.estimatedItems || [];

  for (const room of inventory.rooms) {
    const category = config.roomCategories.find(({ id }) => id === room.categoryID)!;
    rooms.set(category, room);
  }

  for (const estimation of inventory.estimations) {
    const category = config.estimationCategories.find(({ id }) => id === estimation.categoryID)!;
    const estimations = category.packable ? packableEstimations : unpackableEstimations;
    estimations.set(category, estimation);
  }

  return {
    roomsComplete: rooms.size !== 0,
    extraItemsComplete: true,
    packingCategoriesComplete: true,
    hasExtraItems: extraItems.length > 0,
    isFullMoveOut,
    needsPackingHelp,
    requestedMovers,
    rooms,
    packableEstimations,
    unpackableEstimations,
    extraItems,
  };
};
