import * as React from 'react';

import { AddressDetailBuildingType } from '@shared/types/address_detail';
import { AddressDetailField } from '@shared/types/address_detail';
import { IAddressDetail } from '@shared/types/address_detail';
import { IAddressValidationErrors } from '@shared/types/address_detail';
import { IAddressWithDetails } from '@shared/types/address';

import { Spacing } from '@shared/components/bootstrap';
import { AptSuite } from './form/apt_suite';
import { BuildingType } from './form/building_type';
import { StreetAddress } from './form/street_address';

import { Apartment } from './form/buildings/apartment';
import { Commercial } from './form/buildings/commercial';
import { Default } from './form/buildings/default';
import { DonationCenter } from './form/buildings/donation_center';
import { Dorm } from './form/buildings/dorm';
import { House } from './form/buildings/house';
import { Warehouse } from './form/buildings/warehouse';

import { AdditionalInstructions as ParkingAdditionalInstructions } from './form/parking/additional_instructions';
import { LocationType as ParkingLocationType } from './form/parking/location_type';
import { MaxTruckHeight as ParkingMaxTruckHeight } from './form/parking/max_truck_height';
import { PreciseLocation as ParkingPreciseLocation } from './form/parking/precise_location';
import { SpecialCases as ParkingSpecialCases } from './form/parking/special_cases';

import { Header } from '../helpers/header';
import * as Utils from './utils';

export const AddressForm: React.FC<{
  title?: string;
  errors?: IAddressValidationErrors;
  address: IAddressWithDetails;
  setAddress(address: IAddressWithDetails): void;
  disableAddressChanges?: boolean;
}> = ({ title, errors, address, setAddress, disableAddressChanges = false }) => {
  const details = address.details || Utils.DEFAULT_DETAILS;
  const buildingType = details[AddressDetailField.BuildingType];
  const setDetail = (value: IAddressDetail) => setAddress({ ...address, details: value });

  return (
    <div className="row">
      {title && (
        <div className="col-sm-12">
          <Spacing mt={4} mb={4} tag="div">
            <hr />
            <Header tag="h3">{title}</Header>
          </Spacing>
        </div>
      )}
      <div className="col-sm-8">
        <StreetAddress
          address={address}
          validationError={errors?.streetAddress}
          onSelect={(value) => setAddress({ ...address, ...value })}
          disabled={disableAddressChanges}
        />
        <AptSuite
          address={address}
          disabled={!address}
          error={errors?.aptsuite}
          onChange={(aptsuite) => setAddress({ ...address, aptsuite: aptsuite || undefined })}
        />
        <BuildingType
          disabled={!address}
          details={details}
          error={errors?.[AddressDetailField.BuildingType]}
          onChange={(value) => setDetail({ ...details, [AddressDetailField.BuildingType]: value })}
        />
      </div>
      <div className="col-sm-12">
        <Spacing mt={4} mb={4} tag="div">
          <hr />
          <Header tag="h3">Location Details</Header>
        </Spacing>
      </div>
      <div className="col-sm-8">
        {(() => {
          switch (buildingType) {
            case AddressDetailBuildingType.House:
              return <House details={details} setDetails={setDetail} errors={errors} />;
            case AddressDetailBuildingType.Apartment:
              return <Apartment details={details} setDetails={setDetail} errors={errors} />;
            case AddressDetailBuildingType.Dorm:
              return <Dorm details={details} setDetails={setDetail} errors={errors} />;
            case AddressDetailBuildingType.Warehouse:
              return (
                <Warehouse
                  address={address}
                  setAddress={setAddress}
                  details={details}
                  setDetails={setDetail}
                  errors={errors}
                />
              );
            case AddressDetailBuildingType.Commercial:
              return (
                <Commercial
                  address={address}
                  setAddress={setAddress}
                  details={details}
                  setDetails={setDetail}
                  errors={errors}
                />
              );
            case AddressDetailBuildingType.DonationCenter:
              return (
                <DonationCenter
                  address={address}
                  setAddress={setAddress}
                  details={details}
                  setDetails={setDetail}
                  errors={errors}
                />
              );
            default:
              return <Default details={details} setDetails={setDetail} errors={errors} />;
          }
        })()}
      </div>
      <div className="col-sm-12">
        <Spacing mt={4} mb={4} tag="div">
          <hr />
          <Header tag="h3">Parking Information</Header>
        </Spacing>
      </div>
      <div className="col-sm-8">
        <ParkingLocationType details={details} setDetails={setDetail} error={errors?.parkingLocation} />
        <ParkingPreciseLocation address={address} details={details} setDetails={setDetail} />
        <ParkingMaxTruckHeight details={details} setDetails={setDetail} />
        <ParkingSpecialCases details={details} setDetails={setDetail} />
        <ParkingAdditionalInstructions
          value={details[AddressDetailField.ParkingInstructions]}
          onChange={(value) =>
            setDetail({
              ...details,
              [AddressDetailField.ParkingInstructions]: value,
            })
          }
        />
      </div>
    </div>
  );
};
