import styled from '@emotion/styled';
import React from 'react';

import { PerItemInfoFooter } from '@portal/components/orders/per_item_info_footer';
import { Footer } from '@portal/components/orders/steps/base';
import { OrderInput } from '@portal/schema';
import { IEstimatedItem } from '@shared/types/estimated_item';
import { IEstimationInput } from '@shared/types/estimation_input';
import { IRoomInput } from '@shared/types/room_input';
import { IPickupEstimate } from '@shared/types/per_item_pricing';

import { useItemPricingQuery } from '@portal/schema';

// Margin added so that the fixed footer doesn't cover the page
const PageMargin = styled.div`
  height: 250px;
`;

const ButtonContainer = styled.div`
  padding: 16px 24px;
  max-width: 1140px;
  display: flex;
  justify-content: space-between;
  @media (min-width: 600px) {
    margin: 0 auto;
    button {
      min-width: 150px;
    }
  }
`;

export enum ItemPricingInputKind {
  Booking = 'BOOKING',
  Editing = 'EDITING',
}

export interface IOrderEdits {
  itemIDs?: string[];
  estimatedItems?: IEstimatedItem[];
  estimations?: IEstimationInput[];
  rooms?: IRoomInput[];
  scheduled?: string;
}

export interface IItemPricing {
  perItemFee: string;
  appointmentFee: string;
  pickupEstimate: IPickupEstimate;
  returnedItemsCount: number;
  price: string;
}

interface IPerItemOrderFooter {
  orderID?: string;
  order?: OrderInput;
  orderEdits?: IOrderEdits;
  children?: React.ReactNode;
}

export const PerItemOrderFooter = ({ order, orderEdits, children, orderID }: IPerItemOrderFooter) => {
  const { data } = useItemPricingQuery({
    variables: { input: { kind: ItemPricingInputKind.Editing, orderID: orderID, order, orderEdits } },
  });
  return (
    <div>
      <PageMargin />
      <Footer>
        <PerItemInfoFooter pricingInfo={data?.itemPricing} />
        {children && <ButtonContainer>{children}</ButtonContainer>}
      </Footer>
    </div>
  );
};
