import { client } from '@portal/libraries/apollo';
import {
  AddressInput,
  AddressListDocument,
  AddressWithDetailsFragment,
  AppointmentHubDocument,
  useAppointmentDetailsQuery,
  useMovingAddressUpdateMutation,
} from '@portal/schema';
import { FlashKind } from '@shared/types/flash';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { omit } from 'lodash';
import { orderURL } from '@portal/config/routes';
import { MovingToggle } from '@portal/components/addresses/list/moving_toggle';
import qs from 'qs';
import { OrderAddressForm } from '../address/form';
import { Container } from '../container';

const SUCCESS_MESSAGE = 'Order has been successfully updated.';

export const Address: React.FC<RouteComponentProps<{ orderID: string }>> = ({
  match: {
    params: { orderID },
  },
  history,
}) => {
  const [selection, setSelection] = useState<'origin' | 'destination'>(
    qs.parse(location.search, { ignoreQueryPrefix: true })?.kind,
  );
  const [origin, setOrigin] = useState<AddressWithDetailsFragment | undefined>();
  const [destination, setDestination] = useState<AddressWithDetailsFragment | undefined>();
  const [error, setError] = useState<string | undefined>();

  const { data: appointmentData, loading } = useAppointmentDetailsQuery({
    variables: { orderID },
    onCompleted: ({ order }) => {
      setOrigin(order.movingOperation?.originAddress);
      setDestination(order.movingOperation?.destinationAddress);
    },
  });

  const order = appointmentData?.order;

  const [addressUpdate, { loading: addressUpdateloading }] = useMovingAddressUpdateMutation({
    client,
    refetchQueries: [{ query: AppointmentHubDocument, variables: { orderID } }, { query: AddressListDocument }],
  });

  const onSubmit = async () => {
    setError(undefined);

    const { data: addressUpdateData } = await addressUpdate({
      variables: {
        input: {
          orderID: order!.id,
          addressID: selection === 'origin' ? origin!.id! : destination!.id,
          addressKind: selection!,
          addressInput: omit(selection === 'origin' ? origin : destination, [
            '__typename',
            'details.__typename',
            'serviceable',
            'outsideServiceArea',
            'region',
            'formatted',
            'formattedWithoutApartmentNumber',
            'stateAbbreviation',
          ]) as AddressInput,
        },
      },
    });

    if (addressUpdateData?.movingAddressUpdate?.error) {
      setError(addressUpdateData?.movingAddressUpdate?.error);
    } else {
      history.push(orderURL(orderID), {
        flash: {
          kind: FlashKind.Success,
          message: SUCCESS_MESSAGE,
        },
      });
    }
  };

  return order ? (
    <Container breadcrumb="Address" orderID={order.id} scheduled={order?.scheduled} error={error}>
      <OrderAddressForm
        loading={loading || addressUpdateloading}
        selection={selection === 'origin' ? origin : destination}
        onSubmit={onSubmit}
        onChange={(value) => (selection === 'origin' ? setOrigin(value) : setDestination(value))}
      >
        {origin && destination && (
          <MovingToggle
            selection={selection}
            origin={origin}
            destination={destination}
            orderID={orderID}
            onlyAllowAddressDetailChanges={!order.permissions.relocatable}
            setSelection={setSelection}
          />
        )}
      </OrderAddressForm>
    </Container>
  ) : (
    <></>
  );
};
