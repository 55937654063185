import * as React from 'react';

import { IAddress } from '@shared/types/address';

import { InputFormGroup } from './input_form_group';

export const AptSuite: React.FC<{
  address?: IAddress;
  disabled: boolean;
  error?: string;
  onChange(value?: string): void;
}> = ({ address, disabled, onChange, error }) => (
  <InputFormGroup
    label="Unit number"
    error={!address?.aptsuite ? error : undefined}
    disabled={disabled}
    type="text"
    value={address?.aptsuite ?? ''}
    onChange={(event) => onChange(event.target.value ?? undefined)}
    id="apt-suite"
  />
);
