import { createContext } from 'react';

import { IOrderInventory } from '@shared/types/order_inventory';

export const InventoryContext = createContext<{
  data: IOrderInventory;
  onSelect(data: IOrderInventory): void;
}>({
  data: {},
  onSelect: () => {
    /* noop */
  },
});
