import { DateTime, Duration, Interval } from 'luxon';
import React, { useEffect, useState } from 'react';

import { BasicScheduledDatePicker } from '@portal/components/helpers/basic_scheduled_date_picker';
import { AvailabilitiesNavigationKind } from '@portal/components/helpers/availabilities';

import { OrderServiceTypeEnum, AvailabilityFragment, Pricing__LaborRate } from '@portal/schema';

import { isAllDaySLA } from '@shared/utils/all_day_sla';
import { LaborRateScheduledDatePicker } from './labor_rate_scheduled_date_picker';
import { Waitlist } from './waitlist';
import { LaborRateData } from '../orders/steps/scheduled';

const MONTH = 'month';
const CALENDAR_MAX_OFFSET = { years: 1 };
const FROM_ISO_OPTIONS = { setZone: true };

export type DateAvailability = {
  available: boolean;
  blocked: boolean;
  waitlisted: boolean;
  laborRateData?: LaborRateData;
};

function showLaborRateCalendar(availabilities: AvailabilityFragment[]) {
  return availabilities.some(
    (availability) => availability.laborRate?.amount !== 0 && typeof availability.laborRate?.amount === 'number',
  );
}

export const ScheduledDatePicker: React.FC<{
  availabilities: AvailabilityFragment[];
  confirmedDate?: boolean;
  date?: DateTime;
  from?: DateTime;
  till?: DateTime;
  serviceType?: OrderServiceTypeEnum;
  additionalInfo?: React.ReactNode;
  laborRateData?: LaborRateData;
  baseLaborRate?: Pricing__LaborRate;
  showNav: boolean;
  waitlistEligible?: boolean;
  waitlistedDates?: DateTime[];
  rescheduling?: boolean;
  loading?: boolean;
  trackDateSelection?: boolean;
  isAccountCanceled: boolean;
  isMove: boolean;
  onLaborRate?(data: LaborRateData): void;
  onDate(date: DateTime): void;
  onInterval(intervalConfig?: { interval: Interval; forced: boolean }): void;
  fetchAvailabilities?(): void;
  fetchMore?(navigation: AvailabilitiesNavigationKind): void;
  onAddWaitlistDate?(date: DateTime): void;
  onRemoveWaitlistDate?(date: DateTime): void;
  setConfirmedDate?(_: boolean): void;
}> = ({
  availabilities,
  date,
  confirmedDate = true,
  laborRateData,
  baseLaborRate,
  onDate,
  onInterval,
  onLaborRate,
  from,
  till,
  fetchMore,
  serviceType,
  additionalInfo,
  showNav,
  waitlistEligible = false,
  waitlistedDates = [],
  rescheduling = false,
  loading = false,
  trackDateSelection = true,
  isAccountCanceled,
  isMove,
  onAddWaitlistDate,
  onRemoveWaitlistDate,
  setConfirmedDate,
}) => {
  const [initialDateRequest, setInitialDateRequest] = useState<DateTime | undefined>(undefined);
  const [overrideUnavailability, setOverrideUnavailability] = useState<boolean>(false);

  useEffect(() => {
    if (date) {
      const intervals = availabilities.reduce<Interval[]>((memo, availability) => {
        if ((availability.available || overrideUnavailability) && availability.duration) {
          const start = DateTime.fromISO(availability.datetime, { setZone: true });
          const duration = Duration.fromISO(availability.duration!);
          memo.push(Interval.after(start, duration));
        }
        return memo;
      }, []);

      const matches = intervals.filter(
        ({ start: { year, month, day } }) => year === date.year && month === date.month && day === date.day,
      );
      const allDayInterval = matches.find(({ start, end }) => isAllDaySLA(start.hour, end.hour));
      if (!confirmedDate && (!rescheduling || waitlistedDates.length > 0)) {
        const waitlistAvailability = availabilities.find((availability) => {
          const start = DateTime.fromISO(availability.datetime, { setZone: true });
          return (
            !availability.available &&
            availability.waitlistable &&
            start.year === date.year &&
            start.month === date.month &&
            start.day === date.day
          );
        });
        if (waitlistAvailability) {
          const start = DateTime.fromISO(waitlistAvailability.datetime, { setZone: true });
          const duration = Duration.fromISO(waitlistAvailability.duration!);
          onInterval({ interval: Interval.after(start, duration), forced: true });
        }
      } else if (matches.length === 1 && serviceType === OrderServiceTypeEnum.Facility) {
        onInterval({ interval: matches[0], forced: true });
      } else if (allDayInterval) {
        onInterval({ interval: allDayInterval, forced: matches.length === 1 });
      }
    }
  }, [date, availabilities, overrideUnavailability]);

  // There could be multiple availability records per date, but available, laborRate, and
  // perMoverHourAdjustmentAmount will all be consistent across individual dates
  const indexedAvailabilities = new Map<string, DateAvailability>(
    availabilities.map((availability) => {
      const { datetime, available, perMoverHourAdjustmentAmount, laborRate, waitlistable } = availability;
      const dateLaborRateData =
        laborRate && typeof perMoverHourAdjustmentAmount === 'number'
          ? { perMoverHourAdjustmentAmount: perMoverHourAdjustmentAmount, laborRate: laborRate }
          : undefined;
      const displayAvailable = available;
      const blocked = waitlistEligible ? !waitlistable && !available : !available;
      const waitlisted = waitlistEligible && waitlistable && !available;
      const dateAvailability: DateAvailability = {
        available: displayAvailable,
        blocked,
        waitlisted,
        laborRateData: dateLaborRateData,
      };

      return [DateTime.fromISO(datetime, FROM_ISO_OPTIONS).toISODate(), dateAvailability];
    }),
  );

  const onConfirmedDate = (dateTime: DateTime) => {
    const availability = indexedAvailabilities.get(dateTime.toISODate());
    if (setConfirmedDate) setConfirmedDate(!!availability?.available || overrideUnavailability);
  };

  useEffect(() => {
    if (date) onConfirmedDate(date);
  }, [availabilities, overrideUnavailability]);

  const onDateChange = (dateTime: DateTime) => {
    onConfirmedDate(dateTime);
    onDate(dateTime);
  };

  const previousNavEnabled = from && from > DateTime.local().startOf(MONTH);
  const nextNavEnabled = till && till < DateTime.local().plus(CALENDAR_MAX_OFFSET).endOf(MONTH);
  const previousMonth =
    previousNavEnabled && fetchMore ? () => fetchMore(AvailabilitiesNavigationKind.Previous) : undefined;
  const nextMonth = nextNavEnabled && fetchMore ? () => fetchMore(AvailabilitiesNavigationKind.Next) : undefined;
  const laborRateCalendar =
    baseLaborRate && onLaborRate && showLaborRateCalendar(availabilities) && (!isAccountCanceled || isMove);
  const showWaitlist = date && from && till && waitlistEligible && onAddWaitlistDate && onRemoveWaitlistDate;
  const blocked = date && !!indexedAvailabilities.get(date.toISODate())?.blocked;
  const showUnavailableBanner = !!(blocked && !rescheduling);

  return (
    <>
      {laborRateCalendar ? (
        <LaborRateScheduledDatePicker
          indexedAvailabilities={indexedAvailabilities}
          date={date}
          onDate={onDateChange}
          initialDateRequest={initialDateRequest}
          onInitialDateRequest={setInitialDateRequest}
          laborRateData={laborRateData}
          onLaborRate={onLaborRate}
          baseLaborRate={baseLaborRate}
          onInterval={onInterval}
          from={from}
          till={till}
          onPrevious={previousMonth}
          onNext={nextMonth}
          showNav={showNav}
          loading={loading}
          includeWaitlist={!!showWaitlist}
          rescheduling={rescheduling}
          showUnavailableBanner={showUnavailableBanner}
          trackDateSelection={trackDateSelection}
          overrideUnavailability={overrideUnavailability}
          setOverrideUnavailability={setOverrideUnavailability}
        />
      ) : (
        <BasicScheduledDatePicker
          indexedAvailabilities={indexedAvailabilities}
          date={date}
          onDate={onDateChange}
          initialDateRequest={initialDateRequest}
          onInitialDateRequest={setInitialDateRequest}
          onInterval={onInterval}
          from={from}
          till={till}
          additionalInfo={additionalInfo}
          onPrevious={previousMonth}
          onNext={nextMonth}
          showNav={showNav}
          loading={loading}
          includeWaitlist={!!showWaitlist}
          rescheduling={rescheduling}
          showUnavailableBanner={showUnavailableBanner}
          trackDateSelection={trackDateSelection}
          overrideUnavailability={overrideUnavailability}
          setOverrideUnavailability={setOverrideUnavailability}
        />
      )}
      {showWaitlist && (
        <Waitlist
          date={date}
          indexedAvailabilities={indexedAvailabilities}
          waitlistedDates={waitlistedDates}
          rescheduling={rescheduling}
          onAddWaitlistDate={onAddWaitlistDate}
          onRemoveWaitlistDate={onRemoveWaitlistDate}
        />
      )}
    </>
  );
};
