import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AddressForm } from '@portal/components/addresses/form';
import { Button } from '@clutter/clean';

import { IAddressValidationErrors } from '@shared/types/address_detail';
import { IAddressWithDetails } from '@shared/types/address';

import { useAddressQuery, useBuildAddressMutation } from '@portal/schema';
import { serialize } from './form/mutation';

import { disabled, redirectToAccount, validate } from './utils';
import { Error } from './form/error';

const EditAddress: React.FC<{
  address: IAddressWithDetails;
}> = ({ address: input }) => {
  const [address, setAddress] = useState<IAddressWithDetails>(input);
  const [execute] = useBuildAddressMutation({ onCompleted: redirectToAccount });
  const [errors, setErrors] = useState<IAddressValidationErrors | undefined>(undefined);
  const [submissionError, setSubmissionError] = useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (address && !disabled(address)) {
      setSubmissionError(undefined);
    }
  }, [address]);

  const onFormSubmit = () => {
    if (disabled(address)) {
      setErrors(validate(address));
      setSubmissionError('Complete all required fields to save changes');
    } else {
      execute({ variables: { input: serialize(address) } });
    }
  };

  return (
    <React.Fragment>
      <AddressForm
        title="Edit Address"
        address={address}
        setAddress={setAddress}
        errors={errors}
        disableAddressChanges
      />
      {submissionError && <Error>{submissionError}</Error>}
      <Button onClick={onFormSubmit} kind="primary" type="button">
        Save Changes
      </Button>
    </React.Fragment>
  );
};

const EditAddressWithQuery: React.FC<RouteComponentProps<{ addressID: string }>> = ({
  match: {
    params: { addressID },
  },
}) => {
  const { data, loading } = useAddressQuery({
    variables: { addressID },
  });

  if (!data || loading) {
    return null;
  }

  return <EditAddress address={data.address} />;
};

export { EditAddressWithQuery as EditAddress };
