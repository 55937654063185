import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

import { itemsURL, orderURL } from '@portal/config/routes';
import { COLORS, Radio, Text } from '@clutter/clean';
import {
  Maybe,
  Onboarding__CancelIntent__Reason as CancelIntentReason,
  RetentionOfferFragment,
  Status,
  useAcceptRetentionOfferMutation,
  useBuildOnboardingCancelIntentMutation,
} from '@portal/schema';
import { OrderDetailsOrder } from '@portal/components/orders/cancel/steps';
import { CancellationUI } from '@portal/components/orders/cancel/reasons/cancellation_ui';
import { TextLink } from '@portal/components/shared/text';
import { Discount } from '@portal/components/retention/discount';
import { Base } from '../base';

const Error = styled.div`
  color: ${COLORS.toucan};
`;

enum ScreenEnum {
  CancelReason,
  RetentionOffer,
  AcceptedOffer,
}

type Props = {
  order: OrderDetailsOrder;
};

const REASON_LABELS: Record<CancelIntentReason, string> = {
  [CancelIntentReason.DontNeedStorage]: 'My plans changed so I no longer need storage',
  [CancelIntentReason.DifferentStorageCompany]: 'Chose another storage company',
  [CancelIntentReason.FoundFreeStorage]: 'Found a free storage option',
  [CancelIntentReason.PickupDateUnavailable]: 'Date I wanted was unavailable',
  [CancelIntentReason.Accident]: "I wanted a storage price quote & didn't mean to reserve",
  [CancelIntentReason.Other]: 'Other',
};
const REASONS = Object.keys(REASON_LABELS) as CancelIntentReason[];

const Title = styled(Text.Title)`
  line-height: 1.2em;
  margin-bottom: 32px;
`;

const Description = styled(Text.Body)`
  margin-bottom: 32px;
`;

const RetentionOffer: React.FC<{
  offer: RetentionOfferFragment;
  accept?: boolean;
  onAccept(accept: boolean): void;
}> = ({ offer, accept, onAccept }) => {
  const radioOptions = [
    {
      value: true,
      label: (
        <Text.Title size="extraSmall">
          Yes, get <Discount offer={offer} discountable="my storage" />
        </Text.Title>
      ),
    },
    {
      value: false,
      label: <Text.Title size="extraSmall">No, continue canceling my storage</Text.Title>,
    },
  ];
  return (
    <>
      <Title size="large">Would you consider staying with Clutter?</Title>
      <Description>
        As a valued Clutter customer, we want to keep your storage convenient and affordable.
        <br />
        <br />
        Stay with Clutter, and{' '}
        <b>
          we’ll give you <Discount offer={offer} discountable="your monthly storage price" />
        </b>
        . No monthly commitment required.
      </Description>
      <Radio.Selector name="retentionOffer" value={accept} options={radioOptions} onChange={onAccept} />
    </>
  );
};

export const AcceptedRetentionOffer: React.FC<{ offer: RetentionOfferFragment }> = ({ offer }) => (
  <>
    <Title size="large">Thank You</Title>
    <Text.Body>
      The <Discount offer={offer} discountable="storage" /> discount has been applied to your account.
      <br />
      <br />
      There is no other action required.
      <br />
      <br />
      Take me back to <TextLink href={itemsURL()}>My Storage</TextLink>.
    </Text.Body>
  </>
);

export const OnboardingReasons = ({ order }: Props) => {
  const history = useHistory();
  const [reason, setReason] = useState<CancelIntentReason | undefined>();
  const [otherReason, setOtherReason] = useState<string | undefined>();
  const [otherReasonError, setOtherReasonError] = useState<string | undefined>();
  const [currentScreen, setCurrentScreen] = useState<ScreenEnum>(ScreenEnum.CancelReason);
  const [error, setError] = useState<Maybe<string>>();
  const [buildCancelIntent, { loading: buildCancelIntentLoading }] = useBuildOnboardingCancelIntentMutation();
  const [acceptRetentionOffer, setAcceptRetentionOffer] = useState<boolean>();
  const [offer, setOffer] = useState<RetentionOfferFragment>();
  const [acceptOffer, { loading: acceptOfferLoading }] = useAcceptRetentionOfferMutation();

  const changeReason = (cancelReason: CancelIntentReason) => {
    setOtherReason(undefined);
    setReason(cancelReason);
  };

  const next = async () => {
    if (!reason) {
      return;
    }
    switch (currentScreen) {
      case ScreenEnum.CancelReason:
        if (reason === CancelIntentReason.Other && !otherReason) {
          setOtherReasonError("This can't be empty");
        } else {
          const result = await buildCancelIntent({ variables: { reason, otherReason, orderID: order.id } });
          if (result?.data?.build?.status === 'OK') {
            const retentionOffer = result.data.build.retentionOffer;
            if (retentionOffer) {
              setOffer(retentionOffer);
              setCurrentScreen(ScreenEnum.RetentionOffer);
            } else {
              history.push(orderURL(order.id, 'cancel/confirm'));
            }
          } else {
            setError('Failed to save cancel reason.');
          }
        }
        break;
      case ScreenEnum.RetentionOffer:
        if (acceptRetentionOffer) {
          const response = await acceptOffer({ variables: { offerID: offer!.id } });
          if (response.data?.accept?.status === Status.Ok) {
            setCurrentScreen(ScreenEnum.AcceptedOffer);
          } else {
            setError(response.data?.accept?.error);
          }
        } else {
          history.push(orderURL(order.id, 'cancel/confirm'));
        }
        break;
    }
  };
  if (currentScreen === ScreenEnum.AcceptedOffer) {
    return <AcceptedRetentionOffer offer={offer!} />;
  }
  let valid = true;
  if (currentScreen === ScreenEnum.CancelReason) {
    valid = !!reason && !buildCancelIntentLoading;
  } else if (currentScreen === ScreenEnum.RetentionOffer) {
    valid = acceptRetentionOffer !== undefined && !acceptOfferLoading;
  }

  const radioOptions = REASONS.map((reasonOption) => ({
    value: reasonOption,
    label: <Text.Body>{REASON_LABELS[reasonOption]}</Text.Body>,
  }));

  return (
    <Base valid={valid} onNext={next} onPrev={() => history.goBack()}>
      {currentScreen === ScreenEnum.CancelReason ? (
        <CancellationUI
          reason={reason}
          setReason={changeReason}
          otherReasonError={otherReasonError}
          otherReason={otherReason}
          setOtherReason={setOtherReason}
          order={order}
          radioOptions={radioOptions}
          title="Why are you canceling your pickup?"
          showRescheduleAndAddressButtons={true}
        />
      ) : currentScreen === ScreenEnum.RetentionOffer ? (
        <RetentionOffer offer={offer!} accept={acceptRetentionOffer} onAccept={setAcceptRetentionOffer} />
      ) : null}
      {error && <Error>{error}</Error>}
    </Base>
  );
};
