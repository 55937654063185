import { DateTime } from 'luxon';

enum OrderSubtypeEnum {
  Cancelation = 'CANCELATION',
  Final = 'FINAL',
  None = 'NONE',
  Onboarding = 'ONBOARDING',
  Packing = 'PACKING',
  Subsequent = 'SUBSEQUENT',
  SupplyKit = 'SUPPLY_KIT',
  Travel = 'TRAVEL',
  Unpacking = 'UNPACKING',
}

export const fulfilledTermCommitment = (
  scheduledDate: DateTime,
  subtype?: OrderSubtypeEnum,
  termCommitment?: {
    contractEndDate?: string | null;
    fulfilled: boolean;
  } | null,
) =>
  subtype !== OrderSubtypeEnum.Final ||
  !termCommitment?.contractEndDate ||
  scheduledDate >= DateTime.fromISO(termCommitment.contractEndDate!) ||
  termCommitment.fulfilled;
