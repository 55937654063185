import * as React from 'react';

import { DetailedClaimFragment } from '@portal/schema';
import { IClaimEntry } from '@portal/types/claim_entry';

import { ListGroup } from '@shared/components/bootstrap';

import { Entry } from './entry';
import { ClaimSelection } from './types';

interface IEntriesProps {
  claim: DetailedClaimFragment;
  entries: IClaimEntry[];
  selected?: ClaimSelection;
  onSelect(selection: ClaimSelection): void;
  onDeselect(selection: ClaimSelection): void;
  onDelete(entry: IClaimEntry): void;
}

export const Entries = ({ claim, entries, onSelect, onDeselect, onDelete, selected }: IEntriesProps) => (
  <ListGroup>
    {entries.map((entry) => (
      <ListGroup.Item key={`${entry.kind}-${entry.selection.id}`}>
        <Entry
          claim={claim}
          entry={entry}
          selected={selected === entry.selection}
          onSelect={onSelect}
          onDeselect={onDeselect}
          onDelete={onDelete}
        />
      </ListGroup.Item>
    ))}
  </ListGroup>
);
