import * as React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { COLORS, Text } from '@clutter/clean';

import { IAccount } from '@portal/types/account';
import { IOrder } from '@shared/types/order';

import { orderURL } from '@portal/config/routes';

import { Spacer } from '@shared/components/helpers';
import { Phone } from '@shared/components/helpers/phone';
import { Order__ContactKind } from '@portal/schema';

import { DISPLAYABLE_VEHICLE_MAPPING } from '../../contact/util';

const CalloutText = styled(Text.Callout)`
  color: ${COLORS.hippo};
`;

export const Contact: React.FC<{
  account: IAccount;
  order: IOrder;
}> = ({ account, order }) => (
  <>
    <Text.Title size="medium">Contact Information</Text.Title>
    <Text.Body>
      {order.contact ? (
        <>
          {order.contact.name} - <Phone value={order.contact.phone} />
          {order.contact.kind === Order__ContactKind.MovingCompany && ' (Moving Company)'}
        </>
      ) : (
        <>
          {account.customer.name} - <Phone value={account.customer.phone} />
        </>
      )}
    </Text.Body>
    {order.pickupVehicleKind && (
      <Text.Body>Vehicle type: {DISPLAYABLE_VEHICLE_MAPPING[order.pickupVehicleKind]}</Text.Body>
    )}
    <Spacer height="1rem" />
    <CalloutText>
      We'll ask you or the person picking up your belongings for identification. Please ensure the details above are for
      the person who will be picking up your belongings. If a moving company is picking up your belongings, include
      their company name and number.
    </CalloutText>
    <Spacer height="1rem" />
    {order.permissions.active && (
      <Link className="btn btn-primary" to={orderURL(order.id, 'contact')}>
        Update Contact
      </Link>
    )}
    <hr />
  </>
);
