import React, { useState } from 'react';

import { AddressForm } from '@portal/components/addresses/form';

import { IAddressValidationErrors } from '@shared/types/address_detail';

import { disabled, validate } from '@portal/components/addresses/utils';
import { Header } from '@portal/components/helpers/header';
import { Alert } from '@shared/components/bootstrap';
import { AddressWithDetailsFragment, OrderServiceTypeEnum, useBuildAddressMutation } from '@portal/schema';
import { serialize } from '@portal/components/addresses/form/mutation';
import { IStepProps } from '../form';
import { Base } from './base';

export const AddressDetails: React.FC<
  {
    address: AddressWithDetailsFragment;
    setAddress(address: AddressWithDetailsFragment): void;
    onNext(): void;
    onPrev(): void;
    serviceType?: OrderServiceTypeEnum;
  } & IStepProps
> = ({ address, setAddress, onNext, onPrev, serviceType }) => {
  const [errors, setErrors] = useState<IAddressValidationErrors | undefined>(undefined);
  const [submissionError, setSubmissionError] = useState<string | undefined>(undefined);

  const [saveAddress, { loading: saving }] = useBuildAddressMutation();

  const shipping = serviceType === OrderServiceTypeEnum.Shipment;
  const title = address.id ? 'Please confirm your address details' : 'Enter address details';

  const next = async () => {
    if (disabled(address, shipping)) {
      setErrors(validate(address, shipping));
      setSubmissionError('Complete all required fields to save changes');
    } else {
      const response = await saveAddress({ variables: { input: serialize(address) } });
      if (response.data?.buildAddress?.error) {
        setSubmissionError(response.data.buildAddress.error);
      } else {
        onNext();
      }
    }
  };

  return (
    <Base loading={saving} onNext={next} onPrev={onPrev}>
      {submissionError && <Alert style="danger">{submissionError}</Alert>}
      <Header tag="h3">{title}</Header>

      <AddressForm address={address} setAddress={setAddress} errors={errors} disableAddressChanges />
    </Base>
  );
};
