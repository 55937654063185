import * as React from 'react';
import { useContext } from 'react';

import { IRoomCategory } from '@shared/types/room_category';
import { IRoomSelection } from '@shared/types/room_selection';

import { CategoriesContext } from '@shared/components/inventory_capture/categories_context';

const DEFAULT_ROOM_SELECTION = { selected: false, quantity: 0 };

export const RoomCategoriesSummaryTable: React.FC<{
  rooms: Map<IRoomCategory, Partial<IRoomSelection>> | undefined;
}> = ({ rooms }) => {
  const config = useContext(CategoriesContext);

  if (!config || !config.roomCategories) {
    return null;
  }

  return (
    <div>
      {config.roomCategories.map((category) => {
        const { selected, quantity } = (rooms && rooms.get(category)) || DEFAULT_ROOM_SELECTION;

        return (
          <div
            key={category.name}
            className="row border-top"
            style={{ margin: 'inherit', paddingTop: '12px', paddingBottom: '12px' }}
          >
            <div className="col-9 col-xs-9" style={{ padding: '0px' }}>
              <span className="text-capitalize">{category.name}</span>
            </div>
            <div className="col-3 col-xs-3 text-right" style={{ padding: '0px' }}>
              <strong>{category.countable ? quantity : selected ? 'Yes' : 'No'}</strong>
            </div>
          </div>
        );
      })}
    </div>
  );
};
