import React, { useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { Spinner } from '@portal/components/helpers';
import { orderURL } from '@portal/config/routes';
import {
  Maybe,
  OrderTypeEnum,
  Status,
  useOrderFormReturnInventoryQuery,
  useUpdateReturnInventoryMutation,
} from '@portal/schema';
import { FlashKind } from '@shared/types/flash';
import { IReturnInventoryInput } from '@shared/types/return_inventory_input';

import { Container } from './container';
import { OrderReturnInventoryForm } from './return_inventory/form';

const UNABLE_TO_EDIT_MESSAGE = 'Items can no longer be added to this order.';
const SUCCESS_MESSAGE = 'Order has been successfully updated.';

export const ReturnInventory: React.FC<RouteComponentProps<{ orderID: string }>> = ({
  match: {
    params: { orderID },
  },
  history,
}) => {
  const [error, setError] = useState<Maybe<string>>();
  const { data, loading } = useOrderFormReturnInventoryQuery({ variables: { orderID } });

  const [updateInventory, { loading: updating }] = useUpdateReturnInventoryMutation();

  const onSubmit = async (input: IReturnInventoryInput) => {
    setError(undefined);
    const response = await updateInventory({ variables: { orderID, input } });

    if (response?.data?.updateReturnInventory) {
      if (response.data.updateReturnInventory.status === Status.Unprocessable) {
        setError(response.data.updateReturnInventory.error);
      } else {
        history.push(orderURL(orderID), {
          flash: {
            kind: FlashKind.Success,
            message: SUCCESS_MESSAGE,
          },
        });
      }
    }
  };

  if (loading || !data) {
    return <Spinner />;
  }

  const { order, account } = data;

  if (!order.permissions.changeableReturnInventory) {
    history.push(orderURL(orderID), {
      flash: {
        kind: FlashKind.Danger,
        message: UNABLE_TO_EDIT_MESSAGE,
      },
    });
  }

  const service = order.services.find(({ type }) => type === OrderTypeEnum.Return);
  if (!service) {
    return <Redirect to={orderURL(orderID)} />;
  }

  return (
    <Container breadcrumb="Return Inventory" orderID={order.id} scheduled={order.scheduled} error={error}>
      <OrderReturnInventoryForm
        updating={updating}
        order={order}
        service={service}
        termCommitment={account.termCommitment}
        onSubmit={onSubmit}
      />
    </Container>
  );
};
