import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { NavigationProvider } from '@portal/contexts';
import { ClaimConsumer } from '@portal/contexts';

import {
  claimIssueSelectionURL,
  claimItemSelectionURL,
  claimPropertySelectionURL,
  claimURL,
  DEFAULT_CLAIM_ACTION,
} from '@portal/config/routes';

import { ClaimStatus } from '@portal/schema';
import { Confirm } from './confirm';
import { Damages } from './damages';
import { Kinds } from './kinds';
import { Lost } from './lost';
import { Ready } from './ready';
import { Review } from './review';
import { Start } from './start';
import { View } from './view';

import { Selection as IssueSelection } from './issue_selection/selection';
import { Selection as ItemSelection } from './item_selection/selection';
import { Selection as PropertySelection } from './property_selection/selection';

import { Steps } from './steps';

export const Wizard = () => (
  <ClaimConsumer>
    {({ claim, onChange }) => {
      if (claim.status !== ClaimStatus.Draft) {
        return (
          <Switch>
            <Route path={claimURL(':uuid', 'review')} render={() => <Review claim={claim} />} />
            <Route path={claimURL(':uuid', 'thanks')} render={() => <View claim={claim} thanks />} />
            <Route path={claimURL(':uuid')} render={() => <View claim={claim} />} />
          </Switch>
        );
      }
      return (
        <Switch>
          <Route
            exact
            path={claimURL(':uuid')}
            render={() => <Redirect to={claim.step || claimURL(claim.uuid, DEFAULT_CLAIM_ACTION)} />}
          />
          <Route
            path={claimURL(':uuid', 'start')}
            render={({ history }) => (
              <Steps history={history} claim={claim} section="start">
                {(step) => (
                  <NavigationProvider value={step}>
                    <Start />
                  </NavigationProvider>
                )}
              </Steps>
            )}
          />
          <Route
            path={claimURL(':uuid', 'kind')}
            render={({ history }) => (
              <Steps history={history} claim={claim} section="kind">
                {(step) => (
                  <NavigationProvider value={step}>
                    <Kinds />
                  </NavigationProvider>
                )}
              </Steps>
            )}
          />
          <Route
            path={claimURL(':uuid', 'ready')}
            render={({ history }) => (
              <Steps history={history} claim={claim} section="ready">
                {(step) => (
                  <NavigationProvider value={step}>
                    <Ready />
                  </NavigationProvider>
                )}
              </Steps>
            )}
          />
          <Route
            path={claimURL(':uuid', 'damages')}
            render={({ history }) => (
              <Steps history={history} claim={claim} section="damages">
                {(step) => (
                  <NavigationProvider value={step}>
                    <Damages />
                  </NavigationProvider>
                )}
              </Steps>
            )}
          />
          <Route
            path={claimURL(':uuid', 'lost')}
            render={({ history }) => (
              <Steps history={history} claim={claim} section="lost">
                {(step) => (
                  <NavigationProvider value={step}>
                    <Lost />
                  </NavigationProvider>
                )}
              </Steps>
            )}
          />
          <Route
            path={claimURL(':uuid', 'confirm')}
            render={({ history }) => (
              <Steps history={history} claim={claim} section="confirm">
                {(step) => (
                  <NavigationProvider value={step}>
                    <Confirm />
                  </NavigationProvider>
                )}
              </Steps>
            )}
          />
          <Route
            path={claimItemSelectionURL(':uuid', ':id')}
            render={({
              match: {
                params: { id: selectionID },
              },
              history,
            }) => (
              <Steps history={history} claim={claim} section="item_selection" selectionID={selectionID}>
                {(step) => <ItemSelection {...step} onChange={onChange} claim={claim} id={selectionID!} />}
              </Steps>
            )}
          />
          <Route
            path={claimPropertySelectionURL(':uuid')}
            render={({ history }) => (
              <Steps history={history} claim={claim} section="property_selection">
                {(step) => <PropertySelection {...step} onChange={onChange} claim={claim} />}
              </Steps>
            )}
          />
          <Route
            path={claimIssueSelectionURL(':uuid')}
            render={({ history }) => (
              <Steps history={history} claim={claim} section="issue_selection">
                {(step) => <IssueSelection {...step} onChange={onChange} claim={claim} />}
              </Steps>
            )}
          />
          <Route path={claimURL(':uuid', 'thanks')} render={() => <View claim={claim} thanks />} />
        </Switch>
      );
    }}
  </ClaimConsumer>
);
