import * as React from 'react';

import { IEstimationCategory } from '@shared/types/estimation_category';
import { IEstimationSelection } from '@shared/types/estimation_selection';

import { Stepper } from '@shared/components/helpers';

export const Option: React.FC<{
  category: IEstimationCategory;
  quantity?: number;
  onChange(changes: Partial<IEstimationSelection>): void;
}> = ({ category: { name }, quantity, onChange }) => (
  <div className="form-group row">
    <label className="col col-form-label">
      <span className="text-capitalize">{name}</span>
    </label>
    <div className="col-auto">
      <Stepper kind="info" value={quantity} onChange={(value) => onChange({ quantity: value })} />
    </div>
  </div>
);
