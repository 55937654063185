import React from 'react';
import { Route, Switch } from 'react-router';

import { Theme } from '@portal/components/helpers';
import { paymentPlansURL } from '@portal/config/routes';

import { Detail } from './detail';

export const PaymentPlans = () => (
  <Theme name="default">
    <Switch>
      <Route path={paymentPlansURL(':offerID')} render={({ match }) => <Detail offerID={match.params.offerID!} />} />
    </Switch>
  </Theme>
);
