import { useState } from 'react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { FlashKind } from '@shared/types/flash';

import { orderURL } from '@portal/config/routes';
import { serialize } from '@portal/components/addresses/form/mutation';
import {
  AddressWithDetailsFragment,
  OrderDetailsDocument,
  useOrderFormAddressSelectorQuery,
  useUpdateOrderAddressMutation,
} from '@portal/schema';
import { Container } from './container';

import { OrderAddressForm } from './address/form';
import { StorageAddress } from '../addresses/list/storage_address';

const SUCCESS_MESSAGE = 'Order has been successfully updated.';

export const Address: React.FC<RouteComponentProps<{ orderID: string }>> = ({
  match: {
    params: { orderID },
  },
  history,
}) => {
  const [error, setError] = useState<string | undefined>();
  const [selection, setSelection] = useState<AddressWithDetailsFragment | undefined>();

  const { data } = useOrderFormAddressSelectorQuery({
    variables: { orderID },
    onCompleted: ({ order }) => {
      setSelection(order.address);
    },
  });

  const [execute, { loading }] = useUpdateOrderAddressMutation({
    onCompleted: ({ result }) => {
      setError(result?.error ?? undefined);
      if (!result?.error) {
        history.push(orderURL(orderID), {
          flash: {
            kind: FlashKind.Success,
            message: SUCCESS_MESSAGE,
          },
        });
      }
    },
    refetchQueries: [{ query: OrderDetailsDocument, variables: { orderID } }],
  });

  if (!data) return null;

  return (
    <Container breadcrumb="Address" orderID={data.order.id} scheduled={data.order.scheduled} error={error}>
      <OrderAddressForm
        loading={loading}
        selection={selection}
        onSubmit={() => {
          setError(undefined);
          execute({
            variables: {
              orderID,
              addressInput: serialize({ ...selection! }),
            },
          });
        }}
        onChange={(value) => {
          setSelection(value);
        }}
      >
        {selection && (
          <StorageAddress
            pickupAddress={selection}
            orderID={orderID}
            onlyAllowAddressDetailChanges={!data.order.permissions.relocatable}
          />
        )}
      </OrderAddressForm>
    </Container>
  );
};
