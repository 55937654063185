import * as React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Text, COLORS } from '@clutter/clean';

import { IAccount } from '@portal/types/account';
import { IOrder } from '@shared/types/order';

import { orderURL } from '@portal/config/routes';

import { Phone } from '@shared/components/helpers/phone';
import { OrderTypeEnum } from '@portal/schema';

const CalloutText = styled(Text.Callout)`
  color: ${COLORS.hippo};
  margin-top: 8px;
  margin-bottom: 24px;
`;

export const Contact: React.FC<{
  account: IAccount;
  order: IOrder;
}> = ({ account, order }) => (
  <>
    <Text.Title size="medium">Contact Information</Text.Title>
    <Text.Body>
      {order.contact ? (
        <>
          {order.contact.name} - <Phone value={order.contact.phone} />
        </>
      ) : (
        <>
          {account.customer.name} - <Phone value={account.customer.phone} />
        </>
      )}
    </Text.Body>
    {order.type === OrderTypeEnum.Pickup && (
      <CalloutText>We'll share these details with the carrier to provide updates or resolve issues.</CalloutText>
    )}
    {!order.shipped && order.type === OrderTypeEnum.Return && (
      <Link className="btn btn-primary" to={orderURL(order.id, 'contact')}>
        Update Contact
      </Link>
    )}
    <hr />
  </>
);
