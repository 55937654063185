import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React from 'react';

import { Order__ContactInput, OrderTypeEnum, BookingDisclaimer } from '@portal/schema';

import { COLORS, Text } from '@clutter/clean';

import { Card } from '@portal/components/addresses/card';
import { PickupInventory, ReturnInventory } from '@portal/components/helpers';
import { IAddressWithDetails } from '@shared/types/address';
import { Alert, Bordered } from '@shared/components/bootstrap';
import { InventoryFragment } from '@portal/components/helpers/inventory/item_card';

import { Section } from '@portal/components/orders/steps/confirm/section';
import { Base } from '../base';
import { IShippingOption, formatService } from '../shipping/options';
import { TermCommitmentPayment } from './term_commitment_payment';
import { ShippingPrice } from './shipping_price';

const CalloutText = styled(Text.Callout)`
  color: ${COLORS.hippo};
  margin-bottom: 32px;
`;

const Body = styled(Text.Body)`
  margin-bottom: 8px;
`;

const Container = styled.div`
  h2 {
    margin-top: 30px;
  }
`;

export const OutboundShipping: React.FC<{
  type: OrderTypeEnum;
  address: IAddressWithDetails;
  contact?: Order__ContactInput;
  selectedItems: InventoryFragment[];
  disclaimers: BookingDisclaimer | undefined;
  loading: boolean;
  error?: string;
  scheduled: DateTime;
  shippingOption: IShippingOption;
  chargeTermCommitment: boolean;
  paymentError?: string;
  paymentSourceID?: string;
  setPaymentSourceID(sourceID: string): void;
  onPrev(): void;
  onSave(): void;
}> = ({
  type,
  address,
  contact,
  selectedItems,
  disclaimers,
  onSave,
  onPrev,
  loading,
  error,
  scheduled,
  shippingOption,
  chargeTermCommitment,
  paymentError,
  paymentSourceID,
  setPaymentSourceID,
}) => {
  const { carrier, service } = shippingOption;

  return (
    <Base onPrev={onPrev} onNext={onSave} loading={loading} nextLabel="Confirm">
      <Container>
        {error && <Alert style="danger">{error}</Alert>}

        <Text.Title size="large">Confirm your shipment details</Text.Title>

        <Section title="Address">
          <Bordered>
            <Card address={address} />
          </Bordered>
        </Section>

        <Section title="Shipping Method">
          <Body>
            {carrier} ({formatService(carrier, service)})
          </Body>
          <CalloutText>We will email you a tracking number after your items ship.</CalloutText>
        </Section>

        {contact && (
          <Section title="Contact Details">
            <Body>
              {contact.name}
              <br />
              {contact.phone}
            </Body>
            <CalloutText>
              We'll provide your contact information to the shipping carrier, in case they need to contact you about
              your shipment.
            </CalloutText>
          </Section>
        )}

        {type === OrderTypeEnum.Pickup && (
          <Section title="Pickup Details">
            <PickupInventory />
          </Section>
        )}

        {type === OrderTypeEnum.Return && (
          <Section title="Return Items">
            <ReturnInventory selections={selectedItems} />
          </Section>
        )}

        {disclaimers && (
          <>
            <Section title="Return Costs">
              <ShippingPrice option={shippingOption} includeService={true} />
            </Section>
            {disclaimers.orderCancellationDisclaimer && (
              <Section title="Cancellation Policy">{disclaimers.orderCancellationDisclaimer}</Section>
            )}
            {disclaimers.accountTerminationDisclaimer && (
              <Section title="Early Termination">{disclaimers.accountTerminationDisclaimer}</Section>
            )}
          </>
        )}

        {chargeTermCommitment && (
          <TermCommitmentPayment
            scheduled={scheduled}
            selectedSourceID={paymentSourceID}
            setSelectedSourceID={setPaymentSourceID}
            error={paymentError}
          />
        )}
      </Container>
    </Base>
  );
};
