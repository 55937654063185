import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { AddressDetailBuildingRestrictionsAnswer } from '@shared/types/address_detail';
import { AddressDetailField } from '@shared/types/address_detail';
import { IAddressDetail } from '@shared/types/address_detail';

import { Tooltip, TooltipPlacement } from '@shared/components/bootstrap';

import { RadioFormGroup } from '../radio_form_group';
import { SelectFormGroup } from '../select_form_group';

const OPTIONS = [
  { label: 'Yes', value: AddressDetailBuildingRestrictionsAnswer.Yes },
  { label: 'No', value: AddressDetailBuildingRestrictionsAnswer.No },
  { label: 'I don’t know', value: AddressDetailBuildingRestrictionsAnswer.Unknown },
];

const UNKNOWN = 'unknown';

const OPENING_HOURS: Hour[] = [
  { label: '6 am', value: 6 },
  { label: '7 am', value: 7 },
  { label: '8 am', value: 8 },
  { label: '9 am', value: 9 },
  { label: '10 am', value: 10 },
  { label: '11 am', value: 11 },
  { label: 'I don’t know', value: UNKNOWN },
];

const CLOSING_HOURS: Hour[] = [
  { label: '6 am', value: 6 },
  { label: '7 am', value: 7 },
  { label: '8 am', value: 8 },
  { label: '9 am', value: 9 },
  { label: '10 am', value: 10 },
  { label: '11 am', value: 11 },
  { label: '12 pm', value: 12 },
  { label: '1 pm', value: 13 },
  { label: '2 pm', value: 14 },
  { label: '3 pm', value: 15 },
  { label: '4 pm', value: 16 },
  { label: '5 pm', value: 17 },
  { label: '6 pm', value: 18 },
  { label: '7 pm', value: 19 },
  { label: 'I don’t know', value: UNKNOWN },
];

const RESTRICTIONS_TITLE =
  'Some buildings have restrictions for when moving companies can enter the building or\
use the service elevator / loading dock. This is especially common in large apartment complexes or offices.\
Please contact your building management to see if there are any restrictions in your building.';

type Hour = {
  label: string;
  value: number | 'unknown';
};

const BuildingHourSelector: React.FC<{
  selection?: number | null;
  label: React.ReactNode;
  hours: Hour[];
  min?: number;
  max?: number;
  onSelect(value: number | undefined): void;
}> = ({ min = 5, max = 20, selection, onSelect, label, hours }) => (
  <SelectFormGroup
    options={hours.filter(
      ({ value }) => value === UNKNOWN || ((max === undefined || value < max) && (min === undefined || value > min)),
    )}
    label={label}
    value={selection ?? UNKNOWN}
    onChange={(value) => onSelect(value === UNKNOWN ? undefined : value)}
  />
);

export const Restrictions: React.FC<{
  details: IAddressDetail;
  initialBuildingRestrictionsAnswer?: string | null;
  error?: string;
  onDetail(details: IAddressDetail): void;
}> = ({ details, onDetail, error }) => {
  let restrictionsMessage = '';

  if (details[AddressDetailField.Elevator] && details[AddressDetailField.LoadingDock]) {
    restrictionsMessage = ', use the elevator, or use the loading dock';
  } else if (details[AddressDetailField.Elevator]) {
    restrictionsMessage = ' or use the elevator';
  } else if (details[AddressDetailField.LoadingDock]) {
    restrictionsMessage = ' or use the loading dock';
  }
  const label = (
    <>
      Does your building have restrictions on when Clutter can enter the building{restrictionsMessage}?{' '}
      <Tooltip title={RESTRICTIONS_TITLE} placement={TooltipPlacement.Bottom}>
        <FontAwesomeIcon icon="question-circle" />
      </Tooltip>{' '}
    </>
  );

  return (
    <>
      <RadioFormGroup
        name="building-restrictions"
        label={label}
        options={OPTIONS}
        value={details[AddressDetailField.BuildingRestrictionsAnswer]}
        error={!details[AddressDetailField.BuildingRestrictionsAnswer] ? error : undefined}
        onChange={(value) =>
          onDetail({
            ...details,
            [AddressDetailField.BuildingOpeningHour]: null,
            [AddressDetailField.BuildingClosingHour]: null,
            [AddressDetailField.BuildingRestrictionsAnswer]: value,
          })
        }
      />

      {details[AddressDetailField.BuildingRestrictionsAnswer] === AddressDetailBuildingRestrictionsAnswer.Yes && (
        <div className="form-group">
          <label>
            When is the earliest time that your building will let us start and the latest time that we can finish?
          </label>
          <div className="row">
            <div className="col-md-6">
              <BuildingHourSelector
                label="Earliest possible appointment start:"
                hours={OPENING_HOURS}
                selection={details[AddressDetailField.BuildingOpeningHour]}
                max={details[AddressDetailField.BuildingClosingHour] || undefined}
                onSelect={(value) =>
                  onDetail({
                    ...details,
                    [AddressDetailField.BuildingOpeningHour]: value,
                  })
                }
              />
            </div>
            <div className="col-md-6">
              <BuildingHourSelector
                label="Latest possible appointment end:"
                hours={CLOSING_HOURS}
                selection={details[AddressDetailField.BuildingClosingHour]}
                min={details[AddressDetailField.BuildingOpeningHour] || undefined}
                onSelect={(value) =>
                  onDetail({
                    ...details,
                    [AddressDetailField.BuildingClosingHour]: value,
                  })
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
