import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  claimIssueSelectionURL,
  claimItemSelectionURL,
  claimPropertySelectionURL,
  claimURL,
} from '@portal/config/routes';
import { isValidClaimEntry } from '@portal/helpers/claims';
import { ClaimItemSelectionKind, DetailedClaimFragment } from '@portal/schema';
import { ClaimEntryKind } from '@portal/types/claim_entry_kind';
import { IClaimEntry } from '@portal/types/claim_entry';

import { Badge } from '@shared/components/bootstrap';
import { Button } from '@shared/components/bootstrap';
import { Text } from '@shared/components/bootstrap';

import { Currency } from '@shared/components/helpers';

import { ClaimSelection } from './types';
import { Attachments } from '../helpers/attachments';
import { CLAIM_ISSUE_SELECTION_KIND_TO_NAME, CLAIM_ISSUE_SELECTION_NAME_PLACEHOLDER } from '../constants';

const Completion = ({ entry }: { entry: IClaimEntry }) => {
  const completed = isValidClaimEntry(entry);
  return (
    <Badge style={completed ? 'success' : 'danger'}>
      <Text transform="uppercase">{completed ? 'Complete' : 'Incomplete'}</Text>
    </Badge>
  );
};

const Kind = ({ entry }: { entry: IClaimEntry }) => (
  <Badge style="info">{entry.kind === ClaimEntryKind.Item ? entry.selection.kind : entry.kind}</Badge>
);

const Name = ({ entry }: { entry: IClaimEntry }) => (
  <strong>
    {entry.kind === ClaimEntryKind.Issue
      ? entry.selection.kind
        ? CLAIM_ISSUE_SELECTION_KIND_TO_NAME[entry.selection.kind]
        : CLAIM_ISSUE_SELECTION_NAME_PLACEHOLDER
      : entry.selection.name || 'UNNAMED'}
  </strong>
);

const Declaration = ({
  entry: {
    selection: { declaration },
  },
  tag = 'div',
}: {
  entry: IClaimEntry;
  tag?: 'div' | 'span';
}) => (
  <Text tag={tag} wrapping="truncate">
    <Text style="muted">Claimed Value:</Text> {declaration ? <Currency value={declaration} /> : '-'}
  </Text>
);

const Details = ({
  claim,
  entry,
  onDeselect,
  onDelete,
}: {
  claim: DetailedClaimFragment;
  entry: IClaimEntry;
  onDeselect(selection: ClaimSelection): void;
  onDelete(entry: IClaimEntry): void;
}) => {
  const redirectURL = claimURL(claim.uuid, 'confirm');
  const redirect = (url: string) => `${url}?redirect_url=${redirectURL}`;
  return (
    <>
      <div className="row">
        <div className="col-8">
          <Completion entry={entry} /> <Kind entry={entry} />
        </div>
        <div className="col-4">
          <Text alignment="right" tag="div">
            <Button kind="link" onClick={() => onDeselect(entry.selection)}>
              Collapse
            </Button>
          </Text>
        </div>
      </div>
      {entry.kind === ClaimEntryKind.Issue && (
        <>
          <div className="row">
            <div className="col-md-4">
              <Link to={redirect(claimIssueSelectionURL(claim.uuid, 'issue'))}>
                <FontAwesomeIcon icon="marker" />
              </Link>{' '}
              <Text style="muted">Issue Type</Text>
              <Text style="danger">
                <strong> *</strong>
              </Text>
              <Text tag="div" wrapping="truncate">
                <strong>
                  <Name entry={entry} />
                </strong>
              </Text>
            </div>
            <div className="col-md-4">
              <Link to={redirect(claimIssueSelectionURL(claim.uuid, 'declaration'))}>
                <FontAwesomeIcon icon="marker" />
              </Link>{' '}
              <Text style="muted">Requesting Compensation</Text>
              <Text style="danger">
                <strong> *</strong>
              </Text>
              <Text tag="div" wrapping="truncate">
                <strong>{entry.selection.compensationRequested ? 'Yes' : 'No'}</strong>
              </Text>
            </div>
            <div className="col-md-4">
              <Link to={redirect(claimIssueSelectionURL(claim.uuid, 'declaration'))}>
                <FontAwesomeIcon icon="marker" />
              </Link>{' '}
              <Text style="muted">Estimated Loss</Text>
              <Text style="danger">
                <strong> *</strong>
              </Text>
              <Text tag="div" wrapping="truncate">
                <strong>{entry.selection.declaration ? <Currency value={entry.selection.declaration} /> : '-'}</strong>
              </Text>
            </div>
          </div>
          <br />
          <>
            <Link to={redirect(claimIssueSelectionURL(claim.uuid, 'description'))}>
              <FontAwesomeIcon icon="marker" />
            </Link>{' '}
            <Text style="muted">Description of Issue</Text>
            <Text style="danger">
              <strong> *</strong>
            </Text>
            <hr />
            <Text tag="div" wrapping="truncate">
              {entry.selection.description}
            </Text>
            <br />
            <Link to={redirect(claimIssueSelectionURL(claim.uuid, 'photos'))}>
              <FontAwesomeIcon icon="marker" />
            </Link>{' '}
            <Text style="muted">Supporting Documentation</Text>
            <hr />
            <Attachments attachments={entry.selection.photos} />
            {!entry.selection.photos.length && (
              <Text tag="p" alignment="center" style="muted">
                no photos have been saved yet
              </Text>
            )}
            <br />
          </>
        </>
      )}
      {entry.kind === ClaimEntryKind.Item && (
        <>
          <div className="row">
            <div className="col-md-4">
              <Link to={redirect(claimItemSelectionURL(claim.uuid, entry.selection.id, 'name'))}>
                <FontAwesomeIcon icon="marker" />
              </Link>{' '}
              <Text style="muted">Item Name</Text>
              <Text style="danger">
                <strong> *</strong>
              </Text>
              <br />
              <Text tag="div" wrapping="truncate">
                <strong>{entry.selection.name || '-'}</strong>
              </Text>
            </div>
            <div className="col-md-4">
              <Link to={redirect(claimItemSelectionURL(claim.uuid, entry.selection.id, 'declaration'))}>
                <FontAwesomeIcon icon="marker" />
              </Link>{' '}
              <Text style="muted">Item Value</Text>
              <Text style="danger">
                <strong> *</strong>
              </Text>
              <Text tag="div" wrapping="truncate">
                <strong>{entry.selection.declaration ? <Currency value={entry.selection.declaration} /> : '-'}</strong>
              </Text>
            </div>
            <div className="col-md-4">
              <Link to={redirect(claimItemSelectionURL(claim.uuid, entry.selection.id, 'product_url'))}>
                <FontAwesomeIcon icon="marker" />
              </Link>{' '}
              <Text style="muted">Item URL</Text>
              <Text tag="div" wrapping="truncate">
                <strong>{entry.selection.productURL || '-'}</strong>
              </Text>
            </div>
          </div>
          <br />
          {entry.selection.kind === ClaimItemSelectionKind.Damaged && (
            <>
              <Link to={redirect(claimItemSelectionURL(claim.uuid, entry.selection.id, 'photos'))}>
                <FontAwesomeIcon icon="marker" />
              </Link>{' '}
              <Text style="muted">Photos</Text>
              {entry.selection.kind === ClaimItemSelectionKind.Damaged && (
                <Text style="danger">
                  <strong> *</strong>
                </Text>
              )}
              <hr />
              <Attachments attachments={entry.selection.photos} />
              {!entry.selection.photos.length && (
                <Text tag="p" alignment="center" style="muted">
                  no photos have been saved yet
                </Text>
              )}
              <br />
            </>
          )}
          {/* Receipts step is now hidden, hide the confirmation screen section as well
          <>
            <Link to={redirect(claimItemSelectionURL(claim.uuid, entry.selection.id, 'receipts'))}>
              <FontAwesomeIcon icon="marker" />
            </Link>
            {' '}
            <Text style="muted">Receipts</Text>
            <hr />
            <Attachments attachments={entry.selection.receipts} />
            {!entry.selection.receipts.length &&
              <Text tag="p" alignment="center" style="muted">
                no receipts have been saved yet
              </Text>
            }
            <br />
          </>
          */}
        </>
      )}

      {entry.kind === ClaimEntryKind.Property && (
        <>
          <div className="row">
            <div className="col-md-4">
              <Link to={redirect(claimPropertySelectionURL(claim.uuid, 'name'))}>
                <FontAwesomeIcon icon="marker" />
              </Link>{' '}
              <Text style="muted">Property Name</Text>
              <Text style="danger">
                <strong> *</strong>
              </Text>
              <Text tag="div" wrapping="truncate">
                <strong>{entry.selection.name || '-'}</strong>
              </Text>
            </div>
            <div className="col-md-4">
              <Link to={redirect(claimPropertySelectionURL(claim.uuid, 'declaration'))}>
                <FontAwesomeIcon icon="marker" />
              </Link>{' '}
              <Text style="muted">Property Value</Text>
              <Text style="danger">
                <strong> *</strong>
              </Text>
              <Text tag="div" wrapping="truncate">
                <strong>{entry.selection.declaration ? <Currency value={entry.selection.declaration} /> : '-'}</strong>
              </Text>
            </div>
            <div className="col-md-4">
              <Link to={redirect(claimPropertySelectionURL(claim.uuid, 'owner'))}>
                <FontAwesomeIcon icon="marker" />
              </Link>{' '}
              <Text style="muted">Property Owner</Text>
              <Text style="danger">
                <strong> *</strong>
              </Text>
              <Text tag="div" wrapping="truncate">
                <strong>{entry.selection.owner ? entry.selection.owner.name : '-'}</strong>
              </Text>
            </div>
          </div>
          <br />
          <>
            <Link to={redirect(claimPropertySelectionURL(claim.uuid, 'photos'))}>
              <FontAwesomeIcon icon="marker" />
            </Link>{' '}
            <Text style="muted">Photos</Text>
            <Text style="danger">
              <strong> *</strong>
            </Text>
            <hr />
            <Attachments attachments={entry.selection.photos} />
            {!entry.selection.photos.length && (
              <Text tag="p" alignment="center" style="muted">
                no photos have been saved yet
              </Text>
            )}
            <br />
          </>
          <>
            <Link to={redirect(claimPropertySelectionURL(claim.uuid, 'quote'))}>
              <FontAwesomeIcon icon="marker" />
            </Link>{' '}
            <Text style="muted">Quotes</Text>
            <hr />
            <Attachments attachments={entry.selection.quotes} />
            {!entry.selection.quotes.length && (
              <Text tag="p" alignment="center" style="muted">
                no quotes have been saved yet
              </Text>
            )}
            <br />
          </>
        </>
      )}

      <Text tag="div" alignment="center">
        <Button block kind="light" onClick={() => onDelete(entry)}>
          Remove ‘<Name entry={entry} />’ Claim
        </Button>
      </Text>
    </>
  );
};

const Summary = ({ entry, onSelect }: { entry: IClaimEntry; onSelect(selection: ClaimSelection): void }) => (
  <div className="row">
    <div className="col-8 col-lg-3 order-lg-1">
      <Completion entry={entry} /> <Kind entry={entry} />
    </div>
    <div className="col-4 col-lg-2 order-lg-4">
      <Text tag="div" alignment="right">
        <Button kind="link" onClick={() => onSelect(entry.selection)}>
          Expand
        </Button>
      </Text>
    </div>
    <div className="col-lg-4 order-lg-2">
      <Text tag="div" wrapping="truncate">
        <Name entry={entry} />
      </Text>
    </div>
    <div className="col-lg-3 order-lg-2">
      <Declaration entry={entry} />
    </div>
  </div>
);

interface IClaimEntryProps {
  selected: boolean;
  claim: DetailedClaimFragment;
  entry: IClaimEntry;
  onSelect(selection: ClaimSelection): void;
  onDeselect(selection: ClaimSelection): void;
  onDelete(entry: IClaimEntry): void;
}

export const Entry = ({ selected, ...props }: IClaimEntryProps) =>
  selected ? <Details {...props} /> : <Summary {...props} />;
