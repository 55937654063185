import * as React from 'react';

import { AddressDetailField } from '@shared/types/address_detail';
import { IAddressDetail } from '@shared/types/address_detail';

import { Input } from '@clutter/clean';
import { CheckboxFormGroup } from '../checkbox_form_group';

const OPTIONS = [
  {
    label: 'A parking permit is required',
    value: AddressDetailField.ParkingPermit,
    key: AddressDetailField.ParkingPermit,
  },
  {
    label: "It's on a steep driveway or road",
    value: AddressDetailField.Steep,
    key: AddressDetailField.Steep,
  },
  {
    label: "There's a service entrance",
    value: AddressDetailField.ServiceEntrance,
    key: AddressDetailField.ServiceEntrance,
  },
  {
    label: "There's a loading dock",
    value: AddressDetailField.LoadingDock,
    key: AddressDetailField.LoadingDock,
  },
  {
    label: 'You need a code to get in',
    value: AddressDetailField.Code,
    key: AddressDetailField.Code,
  },
];

export const SpecialCases: React.FC<{
  details: IAddressDetail;
  setDetails(details: IAddressDetail): void;
}> = ({ details, setDetails }) => (
  <div className="form-group">
    <CheckboxFormGroup
      label="More info on the location?"
      name="special-cases"
      options={OPTIONS}
      values={{
        [AddressDetailField.ParkingPermit]: !!details[AddressDetailField.ParkingPermit],
        [AddressDetailField.Steep]: details[AddressDetailField.Steep],
        [AddressDetailField.ServiceEntrance]: details[AddressDetailField.ServiceEntrance],
        [AddressDetailField.LoadingDock]: details[AddressDetailField.LoadingDock],
        [AddressDetailField.Code]: details[AddressDetailField.Code],
      }}
      onChange={(_, value) => {
        setDetails({
          ...details,
          ...value,
        });
      }}
    />
    <Input
      type="text"
      placeholder="Building or gate code"
      value={details[AddressDetailField.CodeValue] ?? ''}
      disabled={!details[AddressDetailField.Code]}
      onChange={(event) =>
        setDetails({
          ...details,
          [AddressDetailField.CodeValue]: event.target.value || null,
        })
      }
    />
  </div>
);
