import { FIELDS_FOR_ACCESS, FIELDS_FOR_PARKING } from '@portal/components/addresses/utils';
import { AddressDetailField } from '@shared/types/address_detail';
import { IAddressDetail } from '@shared/types/address_detail';

export const FIELD_LABELS = {
  [AddressDetailField.AccessUnknown]: 'Unknown',
  [AddressDetailField.Stairs]: 'Stairs',
  [AddressDetailField.Elevator]: 'Elevator',
  [AddressDetailField.ServiceElevator]: 'Service Elevator',
  [AddressDetailField.ParkingUnknown]: 'Unknown',
  [AddressDetailField.ParkingStreet]: 'Street Parking',
  [AddressDetailField.ParkingLot]: 'Parking Lot',
  [AddressDetailField.ParkingInFront]: 'In front of building',
  [AddressDetailField.ParkingAlley]: 'Alley / behind building',
  [AddressDetailField.ParkingDriveway]: 'Driveway',
};

export const accessFieldsDetail = (details: IAddressDetail) =>
  FIELDS_FOR_ACCESS.filter((field) => details[field])
    .map((field) => FIELD_LABELS[field])
    .join(', ');

export const parkingLocationDetail = (details: IAddressDetail) =>
  FIELDS_FOR_PARKING.filter((field) => details[field])
    .map((field) => FIELD_LABELS[field])
    .join(', ');
