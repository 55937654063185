import React from 'react';

import { Maybe, Shipping__EasyPostShipmentLabel } from '@portal/schema';
import { IOrder } from '@shared/types/order';

import { formatDate } from '@shared/utils';
import { Spacer } from '@shared/components/helpers';

import styled from '@emotion/styled';

import { Text, COLORS } from '@clutter/clean';
import { BOX_NAMES } from '../../steps/shipping/shipping';

const Header = styled(Text.Title)`
  margin-bottom: 16px;
`;

const Subheader = styled(Text.Body)`
  margin-bottom: 24px;
`;

const LabelLink = styled.a`
  color: ${COLORS.tealPrimary};
  &:hover {
    color: ${COLORS.tealBrand};
  }
`;

const DisabledLabelLink = styled(Text.Body)`
  color: ${COLORS.tealPrimary};
  &:hover {
    color: ${COLORS.tealBrand};
  }
`;

const LabelError = styled(Text.Body)`
  color: ${COLORS.toucan};
`;

const ShipmentContents = styled(Text.Body)`
  color: ${COLORS.storm};
  margin-bottom: 24px;
`;

const Label: React.FC<{ label?: Maybe<string>; contents: string; index: number }> = ({ label, contents, index }) => {
  if (!label) {
    return (
      <>
        <DisabledLabelLink>
          <u>Print Label - Box {BOX_NAMES[index]}</u>
        </DisabledLabelLink>
        <LabelError>
          This label is still being created. Please check back again later or contact support for help.
        </LabelError>
        <ShipmentContents>"{contents}"</ShipmentContents>
      </>
    );
  }

  return (
    <>
      <LabelLink href={label} target="_blank" rel="noreferrer">
        <u>Print Label - Box {BOX_NAMES[index]}</u>
      </LabelLink>
      <ShipmentContents>"{contents}"</ShipmentContents>
    </>
  );
};

export const PrintLabels: React.FC<{ order: IOrder; labels: Shipping__EasyPostShipmentLabel[] }> = ({
  order,
  labels,
}) => (
  <>
    <Spacer height="2rem" />
    <Header size="large">Your shipping labels are ready to print.</Header>
    <hr />
    <Subheader>
      Print your labels, and be sure to put them on the right box. Ship your items out with {labels[0].carrier} by{' '}
      {formatDate(order.scheduled)}.
    </Subheader>
    <Subheader>We'll let you know when they arrive.</Subheader>
    {labels.map((label, i) => (
      <Label key={label.easyPostShipmentId} label={label.shipmentLabel} contents={label.shipmentContents} index={i} />
    ))}
    <hr />
  </>
);
