import * as React from 'react';

import { IAddress } from '@shared/types/address';
import { IAddressDetail } from '@shared/types/address_detail';

import { LocationPicker } from '@shared/components/mapbox';

import { Label } from '@clutter/clean';

export const PreciseLocation: React.FC<{
  address: IAddress;
  details: IAddressDetail;
  setDetails(details: IAddressDetail): void;
}> = ({ address, details, setDetails }) => {
  const latitude = details.parkingLatitude || address.latitude;
  const longitude = details.parkingLongitude || address.longitude;

  if (!latitude || !longitude) {
    return null;
  }

  return (
    <div className="form-group">
      <Label>
        Move pin to the parking location. If we can park directly in front of your location, leave the pin at the
        address.
      </Label>
      <LocationPicker
        latitude={latitude}
        longitude={longitude}
        onChange={(lngLat) => {
          setDetails({
            ...details,
            parkingLongitude: lngLat.lng,
            parkingLatitude: lngLat.lat,
          });
        }}
      />
    </div>
  );
};
