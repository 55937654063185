/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import * as React from 'react';

import { FlashKind } from '@shared/types/flash';
import { IInventoryConfig } from '@shared/types/inventory_config';
import { IOrder } from '@shared/types/order';

import { Flash } from '@shared/components/helpers';

import { valid } from '@portal/components/addresses/utils';
import { incompleteOrder } from '@shared/components/inventory_capture/incomplete';

import { orderURL } from '@portal/config/routes';
import { Link } from 'react-router-dom';

const underline = css`
  color: inherit;
  text-decoration: underline;
`;

const AddressFlash: React.FC<{ order: IOrder }> = ({ order }) => (
  <Flash
    flash={{
      message: (
        <React.Fragment>
          Please complete your{' '}
          <Link css={underline} className="text-underline" to={orderURL(order.id, 'address')}>
            address details
          </Link>{' '}
          below
        </React.Fragment>
      ),
      kind: FlashKind.Danger,
    }}
  />
);

const InventoryFlash: React.FC<{ order: IOrder }> = ({ order }) => (
  <Flash
    flash={{
      message: (
        <React.Fragment>
          Please complete your{' '}
          <Link css={underline} className="text-underline" to={orderURL(order.id, 'estimated_items')}>
            storage inventory
          </Link>{' '}
          below
        </React.Fragment>
      ),
      kind: FlashKind.Danger,
    }}
  />
);

const FLASHERS = [
  {
    shouldFlash: (order: IOrder) => !valid(order.address),
    flash: (order: IOrder) => <AddressFlash order={order} key="address-flash" />,
  },
  {
    shouldFlash: (order: IOrder, config?: IInventoryConfig) => config && incompleteOrder(order, config),
    flash: (order: IOrder) => <InventoryFlash order={order} key="inventory-flash" />,
  },
];

export const Alerts: React.FC<{ order: IOrder; config?: IInventoryConfig }> = ({ order, config }) => (
  <React.Fragment>
    {FLASHERS.map(({ shouldFlash, flash }) => shouldFlash(order, config) && flash(order))}
  </React.Fragment>
);
