import { RouteWithTitle } from '@portal/components/shared/route_with_title';
import * as React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import {
  accountURL,
  activateURL,
  addressesURL,
  billingURL,
  claimsURL,
  downsizeURL,
  giftCardURL,
  invoiceURL,
  confirmOrderURL,
  ordersURL,
  paymentPlansURL,
  payURL,
  referralsURL,
  rescheduleOfferURL,
  reviewURL,
  settingsURL,
  supportURL,
  itemsURL,
  coiURL,
  makespaceURL,
  signedOrderSignaturesURL,
  DEFAULT_ITEMS_STATUS_SLUG,
  loginURL,
  planURL,
} from '@portal/config/routes';
import { Layout } from '@portal/components/shared/layout';
import { useTracker } from '@shared/components/google_analytics/use_tracker';

import { Pageview } from '@portal/components/pageview';
import { Account } from './account';
import { Addresses } from './addresses';
import { Billing } from './billing';
import { Claims } from './claims';
import { GiftCard } from './gift_card';
import { Detail as InvoiceDetail } from './invoices/detail';
import { List as ItemsList } from './items/list';
import { Confirm as OrderConfirm } from './orders/confirm';
import { Orders } from './orders';
import { Activate } from './password/activate';
import { PayInvoices } from './pay/pay_invoices';
import { Plan } from './plan';
import { PaymentPlans } from './payment_plans';
import { Rewards } from './rewards/rewards';
import { Review } from './review';
import { Support } from './support';
import { DownsizeForm as PlanDownsizeForm } from './downsize/form';
import { Config } from './config';
import { Settings } from './settings';
import { CertificateOfInsurance } from './certificate_of_insurance';
import { SignedOrderSignatures } from './orders/signatures';
import { MakeSpaceFlow } from './makespace_flow';
import { Login } from './login';
import { Details as RescheduleOfferDetails } from './reschedule_offer/details';

export const App: React.FC = () => (
  <Config>
    <Router>
      <AppContent />
    </Router>
  </Config>
);
App.displayName = 'App';

const AppContent: React.FC = () => {
  useTracker({});
  return (
    <Layout>
      <Pageview />
      <Switch>
        <RouteWithTitle exact path={accountURL()} component={Account} title="Account Details" />
        <RouteWithTitle path={activateURL()} component={Activate} title="Activate" />
        <Route path={addressesURL()} component={Addresses} />
        <Route path={billingURL()} component={Billing} />
        <Route path={claimsURL()} component={Claims} />
        <Route path={invoiceURL(':invoiceID')} component={InvoiceDetail} />
        <RouteWithTitle exact path={itemsURL(':status')} component={ItemsList} title="Items" />
        <Redirect from={itemsURL()} to={itemsURL(DEFAULT_ITEMS_STATUS_SLUG)} />
        <RouteWithTitle exact path={confirmOrderURL(':token')} component={OrderConfirm} title="Confirm Appointment" />
        <RouteWithTitle
          exact
          path={signedOrderSignaturesURL()}
          component={SignedOrderSignatures}
          title="Sign Documents"
        />
        <Route path={ordersURL()} component={Orders} />
        <RouteWithTitle path={paymentPlansURL()} component={PaymentPlans} title="Payment Plans" />
        <RouteWithTitle path={payURL()} component={PayInvoices} title="Pay your Balance" />
        <RouteWithTitle path={planURL()} component={Plan} title="Update Plan" />
        <RouteWithTitle path={giftCardURL()} component={GiftCard} title="Gift Card" />
        <Route path={reviewURL()} component={Review} />
        <RouteWithTitle path={supportURL()} component={Support} title="Support" />
        <RouteWithTitle path={settingsURL()} component={Settings} title="Settings" />
        <RouteWithTitle path={referralsURL()} component={Rewards} title="Rewards" />
        <RouteWithTitle path={downsizeURL()} component={PlanDownsizeForm} title="Downsize" />
        <RouteWithTitle path={coiURL()} component={CertificateOfInsurance} title="Certificate of Insurance" />
        <Route path={makespaceURL()} component={MakeSpaceFlow} />
        <Route path={loginURL()} component={Login} />
        <Route path={rescheduleOfferURL()} component={RescheduleOfferDetails} />
      </Switch>
    </Layout>
  );
};
AppContent.displayName = 'AppContent';
