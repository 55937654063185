import { AddressWithDetailsFragment, AppointmentDetailsDocument, useMovingAddressUpdateMutation } from '@portal/schema';
import { Box, Button, RadioCard } from '@clutter/clean';
import React, { useState } from 'react';
import { Modal as AddressModal } from '@portal/components/addresses/modal';

import { COLORS, Text } from '@clutter/clean';
import { IAddressWithDetails } from '@shared/types/address';
import styled from '@emotion/styled';

const StyledRadioCard = styled(RadioCard)`
  flex: 1;
`;

export const MovingToggle: React.FC<{
  selection: 'origin' | 'destination';
  origin: AddressWithDetailsFragment;
  destination: AddressWithDetailsFragment;
  orderID: string;
  onlyAllowAddressDetailChanges: boolean;
  setSelection(kind: 'origin' | 'destination'): void;
}> = ({ selection, origin, destination, orderID, onlyAllowAddressDetailChanges, setSelection }) => {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState<string>();

  const [saveAddress, { loading }] = useMovingAddressUpdateMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: AppointmentDetailsDocument, variables: { orderID: orderID } }],
  });

  const onAddAddress = async (address: IAddressWithDetails) => {
    const response = await saveAddress({
      variables: {
        input: {
          orderID: orderID,
          addressID: selection === 'origin' ? origin.id : destination.id,
          addressKind: selection,
          addressInput: {
            street: address.street,
            city: address.city,
            state: address.state,
            zip: address.zip,
            aptsuite: address.aptsuite,
            details: { buildingType: address.details?.buildingType, floor: 1, stories: 1 },
          },
        },
      },
    });

    if (response.data?.movingAddressUpdate?.error) {
      setError(response.data.movingAddressUpdate.error);
    } else {
      setError(undefined);
      setShowModal(false);
    }
  };

  return (
    <Box margin="20px 0 40px">
      <Box.Flex gap="16px" flexDirection={['column', null, 'row']}>
        <StyledRadioCard selected={selection === 'origin'} value="origin" onChange={() => setSelection('origin')}>
          <Text.Callout>Starting address</Text.Callout>
          <Text.Title size="extraSmall">{origin.street}</Text.Title>
          <Text.Caption color={COLORS.hippo}>
            {origin.city}, {origin.state} {origin.zip}
          </Text.Caption>
        </StyledRadioCard>
        <StyledRadioCard
          selected={selection === 'destination'}
          value="destination"
          onChange={() => setSelection('destination')}
        >
          <Text.Callout>Destination address</Text.Callout>
          <Text.Title size="extraSmall">{destination.street}</Text.Title>
          <Text.Caption color={COLORS.hippo}>
            {destination.city}, {destination.state} {destination.zip}
          </Text.Caption>
        </StyledRadioCard>
      </Box.Flex>
      {!onlyAllowAddressDetailChanges && (
        <Box margin="16px 0 0">
          <Button kind="secondary" type="button" onClick={() => setShowModal(true)}>
            Edit Address
          </Button>
        </Box>
      )}
      <AddressModal
        isOpen={showModal}
        error={error}
        loading={loading}
        onClose={() => setShowModal(false)}
        withBuildingType={true}
        onAddAddress={onAddAddress}
      />
    </Box>
  );
};
