import { head } from 'lodash';

type ImageFragment = {
  source: string;
};

export const heroURL = ({ images, hero }: { images: ImageFragment[]; hero: number }) => {
  const image = images[hero] || head(images);
  if (!image) {
    return;
  }
  return image.source;
};
