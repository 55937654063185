// NOTE: due to mounting flashes outside a router (for use with ERB and React) - events are used for communication.

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { IFlash } from '@shared/types/flash';

import { EVENTS as FLASHES_EVENTS } from './flashes';

export const Events: React.FC = () => {
  const location = useLocation<{ flash?: IFlash }>();

  useEffect(() => {
    const flash = location.state?.flash;
    const flashes: IFlash[] = [];

    if (flash) flashes.push(flash);

    const event = new CustomEvent(FLASHES_EVENTS.RESET, { detail: { flashes } });
    document.dispatchEvent(event);
  }, [location]);

  return null;
};
