import { omit, pick } from 'lodash';
import { IAddressDetail } from '@shared/types/address_detail';
import { IAddressWithDetails } from '@shared/types/address';

import { AddressInput } from '@portal/schema';

export const serialize = (address: IAddressWithDetails): AddressInput => {
  const detailsInput = omit(address.details, ['__typename']) as IAddressDetail;
  const addressInput = pick(address, [
    'id',
    'street',
    'aptsuite',
    'city',
    'state',
    'country',
    'zip',
    'latitude',
    'longitude',
    'businessName',
  ]) as AddressInput;
  return { ...addressInput, details: detailsInput };
};
