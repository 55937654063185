import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Spinner } from '@portal/components/helpers';
import { orderURL } from '@portal/config/routes';
import {
  OrderCoiRequestInput,
  OrderDetailsDocument,
  Status,
  useBuildOrderCoiRequestMutation,
  useOrderCertificateOfInsuranceQuery,
} from '@portal/schema';
import { FlashKind } from '@shared/types/flash';

import { Container } from './container';
import { OrderCOIForm } from './certificate_of_insurance/form';

const SUCCESS_MESSAGE = 'The request for a COI has been successfully sent!';
const ERROR_MESSAGE = 'The request could not be submitted. Contact Customer Care for assistance.';

export const CertificateOfInsurance: React.FC<RouteComponentProps<{ orderID: string }>> = ({
  match: {
    params: { orderID },
  },
  history,
}) => {
  const [error, setError] = useState<string | undefined>();
  const { data, loading: queryLoading } = useOrderCertificateOfInsuranceQuery({ variables: { orderID } });
  const [execute, { loading: updating }] = useBuildOrderCoiRequestMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: OrderDetailsDocument, variables: { orderID } }],
  });

  const sanitizeInput = (input: OrderCoiRequestInput) => ({
    ...input,
    buildingManagerEmail: input.buildingManagerEmail || undefined,
    buildingManagerName: input.buildingManagerName || undefined,
    tenantName: input.tenantName || undefined,
  });

  const onSubmit = async (input: OrderCoiRequestInput) => {
    setError(undefined);
    const sanitizedInput = sanitizeInput(input);
    const response = await execute({
      variables: {
        input: { ...sanitizedInput, orderID },
      },
    });
    if (response.data?.buildOrderCoiRequest?.status === Status.Ok) {
      history.push(orderURL(orderID), {
        flash: {
          kind: FlashKind.Success,
          message: SUCCESS_MESSAGE,
        },
      });
    } else {
      setError(response.data?.buildOrderCoiRequest?.error || ERROR_MESSAGE);
    }
  };

  if (queryLoading || !data) {
    return <Spinner />;
  }

  const { order, addresses } = data;

  return (
    <Container breadcrumb="Request a COI" orderID={order.id} scheduled={order.scheduled} error={error}>
      <OrderCOIForm email={order.account.customer.email} loading={updating} onSubmit={onSubmit} addresses={addresses} />
    </Container>
  );
};
