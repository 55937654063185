import * as React from 'react';
import { Query } from '@apollo/client/react/components';

import { IInventoryConfig } from '@shared/types/inventory_config';
import { IOrderInventory } from '@shared/types/order_inventory';
import { ConfigWithSetup } from '@shared/components/inventory_capture/config_with_setup';
import { InventoryConfigDocument } from '@portal/schema';

const DEFAULT_INVENTORY_CONFIG: IInventoryConfig = {
  estimationCategories: [],
  roomCategories: [],
};

export const InventoryConfig: React.FC<{
  inventory: IOrderInventory;
  onSelect?(inventory: IOrderInventory): void;
}> = ({ inventory, onSelect, children }) => (
  <Query<IInventoryConfig>
    query={InventoryConfigDocument}
    children={({ data }) => (
      <ConfigWithSetup
        {...DEFAULT_INVENTORY_CONFIG}
        {...data}
        onSelect={onSelect}
        inventory={inventory}
        children={children}
      />
    )}
  />
);
