import * as React from 'react';

import { AddressDetailField } from '@shared/types/address_detail';
import { IAddress } from '@shared/types/address';
import { IAddressDetail } from '@shared/types/address_detail';
import { IAddressValidationErrors } from '@shared/types/address_detail';

import { Access } from './access';
import { AdditionalNotes } from './additional_notes';
import { BusinessName } from './business_name';

import { Restrictions } from './restrictions';

export const DonationCenter: React.FC<{
  address: IAddress;
  details: IAddressDetail;
  errors?: IAddressValidationErrors;
  setAddress(address: IAddress): void;
  setDetails(details: IAddressDetail): void;
}> = ({ address, setAddress, details, setDetails, errors }) => (
  <>
    <BusinessName
      title="Name of Donation Center"
      value={address.businessName}
      error={errors?.businessName}
      onChange={(value) => setAddress({ ...address, businessName: value })}
    />
    <Access details={details} onChange={(values) => setDetails({ ...details, ...values })} />
    <AdditionalNotes
      value={details[AddressDetailField.AdditionalNotes]}
      onChange={(value) => setDetails({ ...details, [AddressDetailField.AdditionalNotes]: value })}
    />
    <Restrictions
      details={details}
      onDetail={setDetails}
      error={errors?.[AddressDetailField.BuildingRestrictionsAnswer]}
      initialBuildingRestrictionsAnswer={details[AddressDetailField.BuildingRestrictionsAnswer]}
    />
  </>
);
