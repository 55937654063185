import * as React from 'react';

import { Text } from '@shared/components/bootstrap';

import { IClaimEntry } from '@portal/types/claim_entry';

import { Currency } from '@shared/components/helpers';

interface ITotalsProps {
  entries: IClaimEntry[];
}

export const Totals = ({ entries }: ITotalsProps) => {
  const total = entries.reduce((memo, { selection: { declaration } }) => memo + (declaration || 0), 0);

  return (
    <Text alignment="right" wrapping="truncate" tag="p">
      <span>Total Claimed Value: </span>
      <strong>
        <Currency value={total} />
      </strong>
    </Text>
  );
};
