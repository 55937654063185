import React from 'react';
import { FontWeight, Text } from '@clutter/clean';

import styled from '@emotion/styled';
import { IOrder } from '@shared/types/order';
import { Spacer } from '@shared/components/helpers';

const FacilityHours = styled.div`
  margin-left: 8px;
  padding: 2px 0;
`;

export const AddressView: React.FC<{ order: IOrder }> = ({ order }) => (
  <>
    <Text.Title size="medium">Facility Address</Text.Title>
    <Text.Body>{order.address.formatted}</Text.Body>
    <Spacer height="1rem" />
    <Text.Body weight={FontWeight.Medium}>Facility hours:</Text.Body>
    {order.facilityWarehouse?.accessHours.map((hours, index) => (
      <FacilityHours key={index}>
        <Text.Body>{hours}</Text.Body>
      </FacilityHours>
    ))}
    <Spacer height="1rem" />
    <Text.Body>
      <strong>Arrival Instructions: </strong>
      {order.facilityWarehouse?.accessInstructions} When you arrive, you will also need to provide the following pin
      code to pick up your items: <strong>{order.customerPinCode}</strong>.
    </Text.Body>
    <hr />
  </>
);
