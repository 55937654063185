import { UserRole } from '@shared/types/user_role';
import { Account__CancelIntent__Reason as CancelIntentReason } from '@shared/types/account_cancel_intent_reason';

export const ACCOUNT_CANCEL_REASON_OPTIONS: Array<{
  value: CancelIntentReason;
  label: string;
  roles: UserRole[];
}> = [
  {
    value: CancelIntentReason.StoragePrice,
    label: 'Monthly storage price is too expensive',
    roles: [],
  },
  {
    value: CancelIntentReason.AppointmentPrice,
    label: 'Returns & Pickups are too expensive',
    roles: [],
  },
  {
    value: CancelIntentReason.PrefersSelfStorage,
    label: 'Prefer to use self-storage',
    roles: [],
  },
  {
    value: CancelIntentReason.NegativeCx,
    label: 'Negative experience',
    roles: [],
  },
  {
    value: CancelIntentReason.NothingToStore,
    label: 'I no longer need storage',
    roles: [],
  },
  {
    value: CancelIntentReason.PayToVacate,
    label: 'Pay-to-Vacate',
    roles: [UserRole.Admin, UserRole.Billing, UserRole.Collections],
  },
];
