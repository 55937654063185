import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

import { orderURL } from '@portal/config/routes';
import { COLORS, Text, Radio } from '@clutter/clean';
import {
  Moving__CancelIntent__Reason as CancelIntentReason,
  Maybe,
  RetentionOfferFragment,
  Status,
  useMovingCancelIntentCreateMutation,
  useAcceptRetentionOfferMutation,
} from '@portal/schema';
import { OrderDetailsOrder } from '@portal/components/orders/cancel/steps';
import { wt } from '@portal/initializers/wt';
import { CancellationUI, trackingParams } from '@portal/components/orders/cancel/reasons/cancellation_ui';
import { Discount } from '@portal/components/retention/discount';
import { Base } from '../base';

interface IReasonsProps {
  order: OrderDetailsOrder;
}

const REASON_LABELS: Record<CancelIntentReason, string> = {
  [CancelIntentReason.SelfMove]: 'I will move the items myself or with friends',
  [CancelIntentReason.PricingHourlyMinimum]: 'The hourly minimum is too high',
  [CancelIntentReason.PricingHourlyRate]: 'The hourly rate is too high',
  [CancelIntentReason.MoverCount]: "I don't need that many movers",
  [CancelIntentReason.DateUnavailable]: 'The date I wanted was unavailable',
  [CancelIntentReason.DontNeedMove]: 'My plans changed and I no longer need to move',
  [CancelIntentReason.QuoteChanged]: 'My quote changed after the virtual walkthrough',
  [CancelIntentReason.CompetitorPrice]: 'I chose another moving company based on price',
  [CancelIntentReason.CompetitorReviews]: 'I chose another moving company based on reviews',
  [CancelIntentReason.Other]: 'Other',
};
const REASONS = Object.keys(REASON_LABELS) as CancelIntentReason[];

const Error = styled.div`
  color: ${COLORS.toucan};
`;

enum ScreenEnum {
  CancelReason,
  RetentionOffer,
  AcceptedOffer,
}

const Title = styled(Text.Title)`
  line-height: 1.2em;
  margin-bottom: 32px;
`;

const Description = styled(Text.Body)`
  margin-bottom: 32px;
`;

const RetentionOffer: React.FC<{
  offer: RetentionOfferFragment;
  accept?: boolean;
  onAccept(accept: boolean): void;
}> = ({ offer, accept, onAccept }) => {
  const radioOptions = [
    {
      value: true,
      label: (
        <Text.Title size="extraSmall">
          Yes, get <Discount offer={offer} discountable="my move" />
        </Text.Title>
      ),
    },
    {
      value: false,
      label: <Text.Title size="extraSmall">No, continue canceling my move</Text.Title>,
    },
  ];
  return (
    <>
      <Title size="large">Would you consider staying with Clutter?</Title>
      <Description>
        As a valued Clutter customer, we want to keep your moving experience convenient and affordable.
        <br />
        <br />
        Move with Clutter, and{' '}
        <b>
          we’ll give you <Discount offer={offer} discountable="your final bill" />
        </b>
        .
      </Description>
      <Radio.Selector name="retentionOffer" value={accept} options={radioOptions} onChange={onAccept} />
    </>
  );
};

export const AcceptedRetentionOffer: React.FC<{ offer: RetentionOfferFragment }> = ({ offer }) => (
  <>
    <Title size="large">Thank You</Title>
    <Text.Body>
      The <Discount offer={offer} /> discount will be applied to your move.
      <br />
      <br />
      There is no other action required.
    </Text.Body>
  </>
);

export const MovingReasons = ({ order }: IReasonsProps) => {
  const history = useHistory();
  const [reason, setReason] = useState<CancelIntentReason | undefined>();
  const [otherReason, setOtherReason] = useState<string | undefined>();
  const [otherReasonError, setOtherReasonError] = useState<string | undefined>();
  const [currentScreen, setCurrentScreen] = useState<ScreenEnum>(ScreenEnum.CancelReason);
  const [acceptRetentionOffer, setAcceptRetentionOffer] = useState<boolean>();
  const [offer, setOffer] = useState<RetentionOfferFragment>();
  const [error, setError] = useState<Maybe<string>>();
  const [createCancelIntent, { loading: createCancelIntentLoading }] = useMovingCancelIntentCreateMutation();
  const [acceptOffer, { loading: acceptOfferLoading }] = useAcceptRetentionOfferMutation();

  const changeReason = (cancelReason: CancelIntentReason) => {
    setOtherReason(undefined);
    setReason(cancelReason);
  };

  const next = async () => {
    if (!reason) {
      return;
    }
    wt.track(trackingParams({ orderID: order.id, objectName: 'cancel_reason', label: 'Cancel Reason' }));
    switch (currentScreen) {
      case ScreenEnum.CancelReason:
        if (reason === CancelIntentReason.Other && !otherReason) {
          setOtherReasonError("This can't be empty");
        } else {
          const result = await createCancelIntent({ variables: { reason, otherReason, orderID: order.id } });
          if (result?.data?.create?.status === Status.Ok) {
            const retentionOffer = result.data.create.retentionOffer;
            if (retentionOffer) {
              setOffer(retentionOffer);
              setCurrentScreen(ScreenEnum.RetentionOffer);
            } else {
              history.push(orderURL(order.id, 'cancel/confirm'));
            }
          } else {
            setError('Failed to save cancel reason.');
          }
        }
        break;
      case ScreenEnum.RetentionOffer:
        if (acceptRetentionOffer) {
          const response = await acceptOffer({ variables: { offerID: offer!.id } });
          if (response.data?.accept?.status === Status.Ok) {
            setCurrentScreen(ScreenEnum.AcceptedOffer);
          } else {
            setError(response.data?.accept?.error);
          }
        } else {
          history.push(orderURL(order.id, 'cancel/confirm'));
        }
        break;
    }
  };
  if (currentScreen === ScreenEnum.AcceptedOffer) {
    return <AcceptedRetentionOffer offer={offer!} />;
  }
  let valid = true;
  if (currentScreen === ScreenEnum.CancelReason) {
    valid = !!reason && !createCancelIntentLoading;
  } else if (currentScreen === ScreenEnum.RetentionOffer) {
    valid = acceptRetentionOffer !== undefined && !acceptOfferLoading;
  }
  const radioOptions = REASONS.map((reasonOption) => ({
    value: reasonOption,
    label: <Text.Body>{REASON_LABELS[reasonOption]}</Text.Body>,
  }));
  return (
    <Base valid={valid} onNext={next} onPrev={() => history.goBack()}>
      {currentScreen === ScreenEnum.CancelReason ? (
        <CancellationUI
          reason={reason}
          setReason={changeReason}
          otherReasonError={otherReasonError}
          otherReason={otherReason}
          setOtherReason={setOtherReason}
          order={order}
          radioOptions={radioOptions}
          title="Why are you canceling your move?"
          showRescheduleAndAddressButtons={true}
        />
      ) : currentScreen === ScreenEnum.RetentionOffer ? (
        <RetentionOffer offer={offer!} accept={acceptRetentionOffer} onAccept={setAcceptRetentionOffer} />
      ) : null}
      {error && <Error>{error}</Error>}
    </Base>
  );
};
