import React from 'react';
import { Link } from 'react-router-dom';
import { Text } from '@clutter/clean';
import { DateTime } from 'luxon';

import { IOrder } from '@shared/types/order';

import { orderURL } from '@portal/config/routes';
import { Spacer } from '@shared/components/helpers';
import { orderDisplayName } from '@portal/utils/order';

const FROM_ISO_OPTIONS = { setZone: true };

export const Scheduled: React.FC<{ order: IOrder }> = ({ order }) => {
  const scheduled = DateTime.fromISO(order.scheduled, FROM_ISO_OPTIONS);

  return (
    <>
      <Spacer height="2rem" />
      <Text.Title size="large">{scheduled.toLocaleString(DateTime.DATE_HUGE)}</Text.Title>

      <Text.Body>Your {orderDisplayName(order)}</Text.Body>
      <br />
      <Text.Body>Arrival Window: </Text.Body>
      <Text.Body>{order.formattedArrivalWindow}</Text.Body>
      {order.permissions.active && (
        <>
          <Spacer height="1rem" />
          {order.permissions.reschedulable ? (
            <Link className="btn btn-primary" to={orderURL(order.id, 'reschedule')}>
              Reschedule
            </Link>
          ) : (
            <>
              <Text.Callout>Your appointment is past the deadline to reschedule.</Text.Callout>
            </>
          )}
        </>
      )}
      <hr />
    </>
  );
};
