import * as React from 'react';

import { Base } from '@portal/components/orders/steps/base';

import { inventoryComplete } from '@portal/utils';
import { InventoryCaptureForm as InventoryForm } from '@shared/components/inventory_capture/form';
import { IOrderInventory } from '@shared/types/order_inventory';
import { IStepProps } from '../orders/form';
import { Header } from '../helpers/header';

export const InventoryCaptureForm: React.FC<
  {
    inventory: IOrderInventory;
    onNext?(): void;
    onPrev?(): void;
  } & IStepProps
> = ({ inventory, onPrev, onNext }) => (
  <Base valid={inventoryComplete(inventory)} onNext={onNext} onPrev={onPrev}>
    <Header tag="h3">What are you planning to add to storage?</Header>
    <InventoryForm hidePacking={false} hideMoving={true} />
  </Base>
);
