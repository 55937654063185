import * as React from 'react';

import { IAddress } from '@shared/types/address';

import { Badge, Media, Spacing } from '@shared/components/bootstrap';

import { StaticMap } from '@shared/components/mapbox/static_map';
import { AddressFragment } from '@portal/schema';
import Maybe from 'graphql/tsutils/Maybe';

export const Card: React.FC<{
  address: IAddress | AddressFragment;
  phoneNumber?: Maybe<string>;
}> = ({ address, phoneNumber }) => (
  <Media>
    <Spacing mr={3}>
      <StaticMap
        alt={address.street}
        latitude={address.latitude!}
        longitude={address.longitude!}
        width={60}
        height={60}
        zoom={12}
        marker={true}
      />
    </Spacing>
    <Media.Body>
      <div>
        {address.street} {address.aptsuite}
      </div>
      <div>
        {address.city}, {address.state} {address.zip}
      </div>
      <div>{!address.serviceable && <Badge style="danger">Unserviceable address</Badge>}</div>
      {phoneNumber && (
        <a type="tel" href={`tel:${phoneNumber}`}>
          {phoneNumber}
        </a>
      )}
    </Media.Body>
  </Media>
);
