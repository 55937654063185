/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { client } from '@portal/libraries/apollo';

import { Shipping__EasyPostInboundShipment, useEasypostInboundShippingOptionsLazyQuery } from '@portal/schema';
import { IAddress } from '@shared/types/address';

import styled from '@emotion/styled';
import { Text, COLORS, Label, Input } from '@clutter/clean';

import { Base } from '@portal/components/orders/steps/base';
import { DetailedBannerAlert } from '@portal/components/shared/detailed_banner_alert';
import { orderStepURL } from '@portal/config/routes';
import { wt } from '@portal/initializers/wt';
import { IInboundShipmentInput } from './shipping/shipping';

interface IErrorMessages {
  length?: string;
  width?: string;
  height?: string;
}

const Header = styled(Text.Title)`
  margin-bottom: 16px;
`;

const Subheader = styled(Text.Body)`
  margin-bottom: 32px;
  color: ${COLORS.storm};
`;

const StyledCaption = styled(Text.Caption)`
  color: ${COLORS.storm};
`;

const StyledLabel = styled(Label)`
  margin-top: 16px;
  margin-bottom: 12px;
`;

const DimensionsInput = styled(Input)`
  width: 100%;
  max-width: 200px;

  ::placeholder {
    text-align: right;
  }
`;

const ContentsInput = styled(Input)`
  width: 100%;
  margin-bottom: 8px;
`;

const Error = styled(Text.Callout)`
  margin-top: 4px;
  color: ${COLORS.toucan};
`;

const BoxHeaders: React.FC<{ boxName: string }> = ({ boxName }) => (
  <div>
    <Header size="large">
      Tell us about <Text color={COLORS.tealBrand}>Box {boxName}</Text>
    </Header>
    <Subheader>
      Add measurements and a description for your online catalog. Write “{boxName}” on the outside of this box to keep
      track.
    </Subheader>
  </div>
);

const MissingRates: React.FC<{
  handleNoEligibility(): void;
}> = ({ handleNoEligibility }) => {
  const heading = 'No shipping options are available!';

  wt.track({
    pageName: 'portal:new_appointment',
    container: 'inbound_shipment_items',
    action: 'display',
    label: heading,
    objectName: 'missing_shipping_rates_banner',
    objectType: 'banner',
  });

  return (
    <DetailedBannerAlert
      handleClick={() => handleNoEligibility()}
      heading={heading}
      subheading={`Unfortunately we could not find shipping rates for this box. It is likely that the dimensions are too big. Please either ship your items in smaller boxes or choose 'Clutter Delivery' to store your items.`}
      linkText="See Clutter Delivery Options"
    />
  );
};

export const InboundShipmentItems: React.FC<{
  onNext(): void;
  onPrev(): void;
  address: IAddress;
  metadataIndex: number;
  setMetadataIndex(metadataIndex: number): void;
  inboundShipments: IInboundShipmentInput[];
  setInboundShipments(inboundShipments: IInboundShipmentInput[]): void;
  availableShippingOptions: Shipping__EasyPostInboundShipment[];
  setAvailableShippingOptions(availableShippingOptions: Shipping__EasyPostInboundShipment[]): void;
}> = ({
  onNext,
  onPrev,
  address,
  metadataIndex,
  setMetadataIndex,
  inboundShipments,
  setInboundShipments,
  availableShippingOptions,
  setAvailableShippingOptions,
}) => {
  const history = useHistory();
  const [errorMessages, setErrorMessages] = useState<IErrorMessages>({
    length: undefined,
    width: undefined,
    height: undefined,
  });
  const [noRates, setNoRates] = useState<boolean>(false);

  const [getShipmentOptions, { data, loading }] = useEasypostInboundShippingOptionsLazyQuery({
    client,
    onCompleted: () => {
      if (data) {
        if (data.easypostInboundShippingOptions[0].rates.length === 0) {
          setNoRates(true);
        } else {
          setAvailableShippingOptions([...availableShippingOptions, data.easypostInboundShippingOptions[0]]);
          setNoRates(false);
          if (metadataIndex + 1 === inboundShipments.length) {
            onNext();
          } else {
            setMetadataIndex(metadataIndex + 1);
          }
        }
      }
    },
  });

  const updateShipment = (name: string, value?: number | string) => {
    const updatedShipments = [...inboundShipments];
    updatedShipments[metadataIndex] = {
      ...updatedShipments[metadataIndex],
      [name]: value,
    };
    setInboundShipments(updatedShipments);
  };

  const onDimensionChange = (name: string, value?: number) => {
    updateShipment(name, value);
    if (value !== undefined && value <= 0) {
      setErrorMessages({ ...errorMessages, [name]: 'Please enter a dimension greater than 0.' });
    } else if (value !== undefined && value > 108) {
      setErrorMessages({
        ...errorMessages,
        [name]: 'This item is likely too big to ship. Please use Clutter Pickup to move it into storage.',
      });
    } else {
      setErrorMessages({ ...errorMessages, [name]: undefined });
    }
  };

  const onContentChange = (name: string, value?: string) => {
    updateShipment(name, value || '');
  };

  const onNextClick = () => {
    getShipmentOptions({
      variables: {
        input: {
          addressID: address.id!,
          inboundItems: [
            {
              name: inboundShipments[metadataIndex].name,
              length: Number(inboundShipments[metadataIndex].length),
              width: Number(inboundShipments[metadataIndex].width),
              height: Number(inboundShipments[metadataIndex].height),
            },
          ],
        },
      },
    });
  };

  const onPrevClick = () => {
    const updatedAvailableShippingOptions = availableShippingOptions.slice(0, availableShippingOptions.length - 1);
    setAvailableShippingOptions(updatedAvailableShippingOptions);
    if (metadataIndex === 0) {
      onPrev();
    } else {
      setMetadataIndex(metadataIndex - 1);
    }
  };

  const currentBoxName = inboundShipments[metadataIndex].name;

  useEffect(() => {
    wt.track({
      pageName: 'portal:new_appointment',
      container: 'inbound_shipment_item_info_screen',
      action: 'display',
      label: `Tell us about Box ${currentBoxName}`,
      objectName: 'inbound_shipment_item_info',
      objectType: 'page',
    });
  }, [currentBoxName]);

  return (
    <Base
      onNext={onNextClick}
      onPrev={onPrevClick}
      loading={loading}
      valid={
        Object.values(errorMessages).every((error) => !error) &&
        inboundShipments[metadataIndex].length !== undefined &&
        inboundShipments[metadataIndex].width !== undefined &&
        inboundShipments[metadataIndex].height !== undefined &&
        inboundShipments[metadataIndex].contentDescription !== ''
      }
    >
      <BoxHeaders boxName={inboundShipments[metadataIndex].name} />
      {noRates && <MissingRates handleNoEligibility={() => history.push(orderStepURL('delivery_option'))} />}
      <StyledLabel>Length</StyledLabel>
      <DimensionsInput
        name="length"
        value={inboundShipments[metadataIndex].length === undefined ? '' : inboundShipments[metadataIndex].length}
        onChange={(event) => {
          onDimensionChange(event.target.name, event.target.value === '' ? undefined : Number(event.target.value));
        }}
        type="number"
        placeholder="inches"
        min={1}
        max={108}
        state={errorMessages.length ? 'error' : undefined}
      />
      {errorMessages.length && <Error>{errorMessages.length}</Error>}
      <StyledLabel>Width</StyledLabel>
      <DimensionsInput
        name="width"
        value={inboundShipments[metadataIndex].width === undefined ? '' : inboundShipments[metadataIndex].width}
        onChange={(event) => {
          onDimensionChange(event.target.name, event.target.value === '' ? undefined : Number(event.target.value));
        }}
        type="number"
        placeholder="inches"
        min={1}
        max={108}
        state={errorMessages.width ? 'error' : undefined}
      />
      {errorMessages.width && <Error>{errorMessages.width}</Error>}
      <StyledLabel>Height</StyledLabel>
      <DimensionsInput
        name="height"
        value={inboundShipments[metadataIndex].height === undefined ? '' : inboundShipments[metadataIndex].height}
        onChange={(event) => {
          onDimensionChange(event.target.name, event.target.value === '' ? undefined : Number(event.target.value));
        }}
        type="number"
        placeholder="inches"
        min={1}
        max={108}
        state={errorMessages.height ? 'error' : undefined}
      />
      {errorMessages.height && <Error>{errorMessages.height}</Error>}
      <StyledLabel>What's in this box?</StyledLabel>
      <ContentsInput
        name="contentDescription"
        value={inboundShipments[metadataIndex].contentDescription}
        onChange={(event) => onContentChange(event.target.name, event.target.value)}
        type="text"
        placeholder="Snorkel gear, games, costumes, etc."
      />
      <StyledCaption>You can search for this label when your item is stored.</StyledCaption>
    </Base>
  );
};
