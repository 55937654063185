import { COLORS } from '@clutter/clean';
import styled from '@emotion/styled';
import * as React from 'react';

import { Page } from '@portal/components/helpers/page';
import { OrderSubtypeEnum, OrderServiceTypeEnum } from '@portal/schema';
import { Button as BootstrapButton } from '@shared/components/bootstrap';
import { useContext } from 'react';
import { PerItemInfoFooter } from '../per_item_info_footer';
import { useItemPricing } from './item_pricing';
import { OrderContext } from './order_context';

const DEFAULT_VALIDATES = 'next';
const DEFAULT_VALID = true;

const CustomPageSection = styled.div<{ padLargeFooter: boolean }>`
  margin-bottom: ${({ padLargeFooter }) => (padLargeFooter ? '250px' : '100px')};
`;

export const Footer = styled.div`
  background-color: white;
  position: fixed;
  bottom: 0%;
  width: 100%;
  visibility: visible;
  z-index: 2;
  box-shadow: 0px 2px 24px rgba(117, 117, 117, 0.15);
  left: 0;
  margin-bottom: -1px;
`;

const ButtonContainer = styled.div`
  padding: 16px 24px;
  max-width: 1140px;
  display: flex;
  justify-content: space-between;
  @media (min-width: 600px) {
    margin: 0 auto;
  }
  button {
    width: 150px;
  }
`;

const FinalReturnButtonContainer = styled(ButtonContainer)`
  @media (max-width: 600px) {
    flex-direction: column;
    & > button {
      margin-bottom: 16px;
    }
  }
  @media (min-width: 600px) {
    text-align: right;
    display: block;
  }
  button {
    width: auto;
  }
`;

const FinalReturnBorderContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLORS.grayBorder};
`;

const FinalReturnCopyContainer = styled.div`
  padding: 16px 24px;
  max-width: 1140px;
  margin: 0 auto;
`;

const FinalReturnTitle = styled.div`
  font-weight: bold;
`;

export const Button = styled(BootstrapButton)`
  font-weight: bold;
  padding: 12px 32px;
  border-radius: 4px;
  &.primary {
    background-color: ${COLORS.tealPrimary};
    color: ${COLORS.cloud};
    border: none;
  }
  &.secondary {
    background-color: ${COLORS.cloud};
    border: 1px solid ${COLORS.tealPrimary};
    color: ${COLORS.tealPrimary};
  }
  &.danger {
    background-color: ${COLORS.cloud};
    border: 1px solid ${COLORS.toucan};
    color: ${COLORS.toucan};
  }
  &:disabled {
    opacity: 0.6;
  }
  :focus {
    outline: none;
  }
`;

const LastButton = styled(Button)`
  margin-bottom: 0px !important;
`;

const DEFAULT_NEXT_LABEL = 'Next';

export const Base: React.FC<{
  validates?: 'next' | 'prev';
  valid?: boolean;
  orderSubtype?: OrderSubtypeEnum;
  onNext?(): void;
  onPrev?(): void;
  loading?: boolean;
  nextLabel?: string;
  scrollOnNext?: boolean;
}> = ({
  validates = DEFAULT_VALIDATES,
  valid = DEFAULT_VALID,
  children,
  onNext,
  onPrev,
  orderSubtype,
  loading,
  nextLabel,
  scrollOnNext = true,
}) => {
  const { order } = useContext(OrderContext);
  const data = useItemPricing();
  const isFinalReturn = orderSubtype === OrderSubtypeEnum.Final;
  const handleNext = () => {
    if (scrollOnNext) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    onNext?.();
  };
  const handlePrev = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    onPrev?.();
  };

  return (
    <Page>
      <CustomPageSection className="page__section" padLargeFooter={!!data?.itemPricing}>
        {children}
      </CustomPageSection>
      {(onNext || onPrev) && (
        <Footer>
          {isFinalReturn ? (
            <>
              <FinalReturnBorderContainer>
                <FinalReturnCopyContainer>
                  <FinalReturnTitle>Closing your account?</FinalReturnTitle>
                  Requesting all your items back will close your account – to keep your current monthly rate, leave one
                  item in storage.
                </FinalReturnCopyContainer>
              </FinalReturnBorderContainer>
              <FinalReturnButtonContainer>
                <LastButton className="danger" disabled={validates === 'next' && !valid} onClick={handleNext}>
                  Continue closing account
                </LastButton>
              </FinalReturnButtonContainer>
            </>
          ) : (
            <>
              {order?.serviceType !== OrderServiceTypeEnum.Shipment && (
                <PerItemInfoFooter pricingInfo={data?.itemPricing} />
              )}
              <ButtonContainer>
                {onPrev && (
                  <Button
                    className="secondary"
                    disabled={(validates === 'prev' && !valid) || loading}
                    onClick={handlePrev}
                  >
                    Back
                  </Button>
                )}
                {onNext && (
                  <Button
                    loading={loading}
                    className="primary"
                    disabled={validates === 'next' && !valid}
                    onClick={handleNext}
                  >
                    {nextLabel || DEFAULT_NEXT_LABEL}
                  </Button>
                )}
              </ButtonContainer>
            </>
          )}
        </Footer>
      )}
    </Page>
  );
};
