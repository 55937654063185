import React, { useEffect, useRef } from 'react';

import { ENV, Environment } from '@shared/config/environment';
import { parseGooglePlaceResult } from '@shared/utils/parse_google_place_result';

const COUNTRIES = ['us', 'ca'];

interface IGoogleMapsPlace {
  types: string[];
  latitude: number;
  longitude: number;
  street?: string;
  city?: string;
  state?: string;
  county?: string;
  country?: string;
  zip?: string;
}

type Callback = (place: IGoogleMapsPlace) => void;

interface IGoogleMapsPlacesAutocompleteInputProps {
  onAutocomplete: Callback;
}

type IProps = IGoogleMapsPlacesAutocompleteInputProps & React.InputHTMLAttributes<HTMLInputElement>;

const useLiveGooglePlaces = (input: React.RefObject<HTMLInputElement>, callback: React.RefObject<Callback>) => {
  useEffect(() => {
    const autocomplete = new google.maps.places.Autocomplete(input.current!, {
      types: ['address'],
      componentRestrictions: { country: COUNTRIES },
    });

    const listener = autocomplete.addListener('place_changed', () => {
      const place = parseGooglePlaceResult(autocomplete.getPlace());
      if (place) {
        callback.current!(place);
      }
    });

    return () => listener.remove();
  }, []);
};

const useTestGooglePlaces = (input: React.RefObject<HTMLInputElement>, callback: React.RefObject<Callback>) => {
  useEffect(() => {
    const listener = (
      event: CustomEvent<{
        place: IGoogleMapsPlace;
      }>,
    ) => {
      const { place } = event.detail;
      input.current!.value = `${place.street} ${place.city} ${place.state} ${place.zip} ${place.country}`;
      callback.current!(place);
    };
    input.current!.addEventListener('GooglePlacesAutocompleteFake', listener as EventListener);
    return () => input.current!.removeEventListener('GooglePlacesAutocompleteFake', listener as EventListener);
  }, []);
};

const useGooglePlaces = ENV === Environment.test ? useTestGooglePlaces : useLiveGooglePlaces;

const useGooglePlacesAutocompleteInputRef = (onAutocomplete: Callback) => {
  const input = useRef<HTMLInputElement>(null);
  const callback = useRef(onAutocomplete);

  useEffect(() => {
    callback.current = onAutocomplete;
  }, [onAutocomplete]);

  useGooglePlaces(input, callback);

  return input;
};

const GoogleMapsPlacesAutocompleteInput: React.FC<IProps> = ({ onAutocomplete, ...props }) => {
  const input = useGooglePlacesAutocompleteInputRef(onAutocomplete);

  const onFocus = () => {
    if (input.current) {
      input.current.autocomplete = 'off';
    }
  };

  return <input {...props} ref={input} onFocus={onFocus} />;
};

export { GoogleMapsPlacesAutocompleteInput, IGoogleMapsPlace, useGooglePlacesAutocompleteInputRef };
