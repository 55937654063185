import * as React from 'react';

import { Button, Spacing } from '@shared/components/bootstrap';
import { IEstimatedItem } from '@shared/types/estimated_item';

export const ExistingItems: React.FC<{
  items: IEstimatedItem[];
  onEditQuantity(index: number, quantity: number | undefined): void;
  onRemove(index: number): void;
}> = ({ items, onEditQuantity, onRemove }) => (
  <>
    <div className="row">
      <div className="col-2 col-md-2">Count</div>
    </div>
    {items.map((item, index) => (
      <Spacing key={index} mb={2}>
        <div className="row">
          <div className="col-2 col-md-2" style={{ paddingRight: 0 }}>
            <input
              required
              className="form-control text-center"
              onChange={(event) => onEditQuantity(index, Number(event.target.value) || undefined)}
              type="number"
              value={item.quantity || ''}
              min="1"
              maxLength={3}
            />
          </div>
          <div className="col-7 col-md-8 d-flex align-items-center">
            <strong>{item.categoryDisplayName || item.customCategoryName}</strong>
          </div>
          <div className="col-3 col-md-2 text-center text-danger" style={{ paddingLeft: 0 }}>
            <Button className="text-danger" kind="link" onClick={() => onRemove(index)}>
              Remove
            </Button>
          </div>
        </div>
      </Spacing>
    ))}
  </>
);
